import React, { Component } from "react";
import { connect } from "react-redux";
import { getPizarras} from "../actions";


class ModPizarras extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            pizarra:1,
            datos:[],
            pos:0
        };
    }

    componentDidMount() {
        this.props.getPizarras()
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.data.pizarras != this.props.data.pizarras) {
        this.setState({
          loading: false,
          datos: this.props.data.pizarras
        });
      }
        /*if(!this.state.loading){
            this.setState({
                loading:false
            })
        }*/
    }

    cambiaPizarra = (pos) =>{
      this.setState({
        pos:pos
      })
    }

    render() {
        if (this.state.loading) return <div>CARGANDO</div>
        var menu = this.state.datos.map((p,i)=>{
          // var tit = p.nombre.toUpperCase()
          var tit = p.nombre
          if(p.nombre=="granos"){
            tit="Pizarra"
          }else if(p.nombre=="piz_dol"){
            tit="Pizarra U$S"
          }
          return <li key={"pm_"+i} className="nav-item" key={"cel_pizarra_"+i}>
                    <a className={(i==0)?"nav-link active": " nav-link"} href={"#tab_"+p.nombre} role="tab" data-toggle="tab" style={{ color: "#5BABBE"}} >{tit}</a>
                  </li>
        })
        var contenido = this.state.datos.map((p,i)=>{
          var titulos=""
          var data=[]
            var columnas = p.datos.map((d,j)=>{
              if(j==0){
                titulos = Object.keys(d).map((dd, j) => {
                  return <td key={"celda_a__"+j} style={{ color: "#5BABBE"}} >{(d[dd]=="ANO")?"AÑO":d[dd]}</td>;
                });
              }else{
                var d_temp = Object.keys(d).map((dd, j) => {
                  return <td key={"celda_b_"+j} style={{ color: "#000"}}>{d[dd]}</td>;
                });
               /* var fila_temp='<tr type="D">'
                for (const prop in d) {
                  fila_temp+=`<td>${d[prop]}</td>` 
                }
                fila_temp+=`</tr>`*/
                data.push(<tr type="D" key={"fila_"+j}>{d_temp}</tr>);
              }

            })

            return <div role="tabpanel" className={(i==0)?"tab-pane active tabla-pizarra": " tab-pane tabla-pizarra"} id={"tab_"+p.nombre} key={"b_"+i}>      
              <table className="table table-striped table-sm" width="100%" id="regiontot"  filter="" adding="false">
                <thead id="tbh">
                  <tr className="table-secondary">
                  {titulos}
                  </tr>
                </thead>
                <tbody id="tbb">
                  <tr type="D"  key={"999999"}>
                  </tr>
                  {data}
                </tbody>
              </table>
            </div>
        })
        return(
             <div>
               {/*Nav tabs*/ }
                <ul className="nav nav-tabs centered" role="tablist">
                 {menu}
                </ul>

                {/*Tab panes*/ }
                <div className="tab-content bg_white">
                  {contenido}                
                </div>
                {/*Tab panes*/ }
            
      	  </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
   getPizarras: () => {
      dispatch(getPizarras());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModPizarras);
