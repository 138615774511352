import React from "react";
import { Link, Redirect } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const ModServicios = props => (

  <div className="container" style={{ background: "#fff"}} data-aos="fade-left" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
    <div className="row box-shadow" style={{marginTop:""}}>
      <div className="col-sm-6 p-5 bg_image" style={{backgroundImage:"url('"+window.location.origin+"/imgs/fertilizantes_agroquimicos.jpg')"}}>
      </div>

      <div className="col-sm-6 p-md-5">
        <div className="p-4">
          <p className="h6 mb-5 txt-primary">
          Contamos con un equipo comercial que brinda soluciones y ofrece asesoramiento en el mercado de granos y capitales. A su vez nuestra eficiencia administrativa y la continua inversión en tecnología, nos permite darle al cliente rapidez y seguridad a la hora de operar con nosotros.
          </p>
          <p>
            <Link to="/servicios/agronegocios" className="btn button-primary px-3 py-2">
              Agronegocios
            </Link>
          </p>
          <p>
            <Link  to="/servicios/mercado_capitales" className="btn button-primary px-3 py-2">
              Mercado de Capitales
            </Link>
          </p>
        </div>
      </div>
      </div>
  </div>
);

export default ModServicios;
