import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import reduxPromises from 'redux-promise'

import App from './components/app'
import reducers from './reducers'

const createStoreWithMiddleware = applyMiddleware(reduxPromises)(createStore);
/*basename={"/prode"}*/
let wa = ( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)) ? "https://wa.me/5491141402553" : "https://web.whatsapp.com/send?phone=5491141402553"
ReactDOM.render(
    <Provider store={createStoreWithMiddleware(reducers)}>
        <Router >
            <Route path="/" component={App} />
        </Router>
    
    </Provider>
    , document.getElementById('root'));
