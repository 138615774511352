import React, { Component} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { sendLogin, resetPass } from "../actions";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'


class LoginFormMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enviando: false,
      user: "",
      pass: "",
      recordarme: "",
      tipo: "agro",
      error: "",
      resp: null,
      blanque: false,
      resetpass: false,
      newpass: "",
      renewpass: "",
      message: "",
      renwepass_ok: false,
      modal_display: 'none',
      title_modal: 'Renovar contraseña',
      input_type_1: 'password',
      input_type_2: 'password',
      input_type_3: 'password',
      icon_1: eyeOff,
      icon_2: eyeOff,
      icon_3: eyeOff
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.contador_login_m != prevProps.data.contador_login_m) {
      if(this.props.data.bloqueado.bloqueado === 5) {
        this.setState({
          error: 'Su usuario se encuentra bloqueado. Por favor comuniquese con su comercial.',
          renwepass_ok: true,
          modal_display: 'flex',
          title_modal: 'Advertencia'
        })
      } else if (this.props.data.respuesta_login_m.resp == 1 && this.props.data.vencimiento.vto === 1) {
        this.setState({
          resetpass: true,
          error: 'Su password ha caducado. Por favor escoga una nueva.',
          modal_display: 'flex',
          title_modal: 'Renovar password'
        })
      } else if (this.props.data.respuesta_login_m.resp == 1 && this.props.data.vencimiento.vto === 0) {
        document.getElementById("log_form_h_m").submit();
      } else {
        this.setState({
          error: this.props.data.respuesta_login_m.msj,
          modal_display: 'flex',
          renwepass_ok: true,
          title_modal: 'Advertencia'
        })
      }
    }
  }

  validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&_*+(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return `La contraseña debe tener al menos ${minLength} caracteres.`;
    }
    if (!hasUpperCase) {
      return 'La contraseña debe tener al menos una letra mayúscula.';
    }
    if (!hasLowerCase) {
      return 'La contraseña debe tener al menos una letra minúscula.';
    }
    if (!hasNumber) {
      return 'La contraseña debe tener al menos un número.';
    }
    if (!hasSpecialChar) {
      return 'La contraseña debe tener al menos uno de estos caracteres especiales !@#$%^&_*+(),.?":{}|<>';
    }
    return '';
  };

  enviaFormLogin = (e) => {
    e.preventDefault();
    if (this.state.user == "") {
      this.setState({
        error: "Debe completar su Usuario"
      });
    } else if (this.state.pass == "") {
      this.setState({
        error: "Debe completar su Password"
      });
    } else {
      const sendData = {
        user: this.state.user,
        pass: this.state.pass,
        f: 2
      };
      this.props.sendLogin(sendData);
    }
  }

  enviaFormResetPass = (e) => {
    e.preventDefault();
    if (this.state.user === "") {
      this.setState({
        error: "Debe completar su Usuario"
      });
    } else if (this.state.pass === "") {
      this.setState({
        error: "Debe completar su Contraseña actual"
      });
    }  else if (this.state.pass === "") {
      this.setState({
        error: "Debe completar su nueva Contraseña"
      });
    }  else if (this.state.pass === "") {
      this.setState({
        error: "Debe completar su nuevamente la nueva Contraseña"
      });
    } else {
      if(this.state.newpass!== this.state.renewpass) {
        this.setState({
        error: "No coinciden las Contraseñas"
        })
      } else {
        const sendData = {
          user: this.state.user,
          pass: this.state.pass,
          newpass: this.state.newpass,
          f: 1
        };

        const validationError = this.validatePassword(this.state.newpass);
        if (validationError) {
          this.setState({ error: validationError, message: "" });
        } else {
          this.setState({ error: "" });
          this.props.resetPass(sendData);
          if(this.props.data.reset_pass.resp === 0) {
            this.setState({
              error: this.props.data.reset_pass.msj,
              modal_display: 'flex',
              title_modal: 'Advertencia',
              message: ''
            })
          } else{
            this.setState({ resetpass: false, message: `Cambio de contraseña exitoso! Por favor ingrese con su nueva contraseña.`, error: "", renwepass_ok: true, pass: this.state.newpass })
          }
        }
        
      }
    }
  }

  handleTogglePassword = (inputNumber) => {
    if(inputNumber === 1) {
      if (this.state.input_type_1==='password'){
         this.setState({ icon_1: eye })
         this.setState({ input_type_1: 'text' })
      } else {
         this.setState({ icon_1: eyeOff })
         this.setState({ input_type_1: 'password' })
      }
    } else if(inputNumber === 2) {
      if (this.state.input_type_2==='password'){
         this.setState({ icon_2: eye })
         this.setState({ input_type_2: 'text' })
      } else {
         this.setState({ icon_2: eyeOff })
         this.setState({ input_type_2: 'password' })
      }
    } else if(inputNumber === 3) {
      if (this.state.input_type_3==='password'){
         this.setState({ icon_3: eye })
         this.setState({ input_type_3: 'text' })
      } else {
         this.setState({ icon_3: eyeOff })
         this.setState({ input_type_3: 'password' })
      }
    }
 }

  cambia = (key, value) => {
    this.setState({ [key]: value });
  };

  cambiaTipo = (value) => {
    this.setState({ tipo: value.target.value });
  };

  goFinanciero = (e) => {
    var dest_financiero = (window.location.origin.includes("allariaagro.com.ar")) ? 'https://financiero.allariaagro.com.ar/' : 'http://groot/'
    window.location = dest_financiero;
  }

  goLogistica = (e) => {
    var dest_logistica = (window.location.origin.includes("allariaagro.com.ar")) ? 'https://logistica.allariaagro.com.ar/' :process.env.REACT_APP_URL_LOGISTICA
    window.location = dest_logistica;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  logoOver =(event)=>{
    window.tLeB(event,'https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=C&v_shortname=SC5&v_search=https://www.allariaagro.com.ar:7510/&x=6&y=5','tl_popupSC5')
  }

  logoMove =(event)=>{
    window.tLXB(event) 
  }

  logoOut =(event)=>{
    window.tLTC('tl_popupSC5')  
  }

  closeModal = () => {
    this.setState({ modal_display: 'none', renwepass_ok: false, newpass: "", renewpass: "" })
  }

  //
  render() {

    var dest = (window.location.origin.includes("allariaagro.com.ar")) ? 'https://clientes.allariaagro.com.ar' : 'http://aeon'

    return (
      <>
        {/* MODAL */}
        <div className="modal_container" style={{ display: `${this.state.modal_display}` }}>
          <div className="modal_reset_pass" style={{ position: 'absolute', width: '90%' }}>
            <div className="modal-header">
              <h5 className="modal-title txt-primary" id="exampleModalCenterTitle">{this.state.title_modal}</h5>
              <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="form-reset-pass" className="w-100">
                <form className="form-inline d-flex flex-column justify-content-center align-items-center" id="log_form3" onSubmit={ this.enviaFormResetPass } >
                  { this.state.error !== "" ?
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div> : 
                      <div className="alert alert-success" role="alert">
                        {this.state.message}
                      </div>
                  }   
                  
                  {!this.state.renwepass_ok ?
                    <>
                      <div className="form-group w-100 pb-4">
                        <label htmlFor="user" className="txt-primary mb-2">Usuario:</label>
                        <input style={{ borderRadius: "30px" }} required="required" name="user" type="text" className="form-control w-100" id="user_m" value={this.state.user} aria-describedby="" onChange={v => this.cambia('user', v.target.value)}/>
                      </div>
                      <>
                        <div className="form-group w-100 pb-4 d-flex flex-column align-items-start">
                          <label htmlFor="pass" className="txt-primary mb-2">Password actual:</label>
                          <div className="input-container d-flex align-items-center px-3 w-100" style={{ borderRadius: "30px", border: '1px solid gray' }}>
                            <input required="required" style={{ border: 'none' }} name="pass" type={this.state.input_type_1} className="form-control w-100 p-0" id="pass_m" value={this.state.pass} onChange={v => this.cambia('pass', v.target.value)} aria-describedby="" />
                            <span className="flex justify-around items-center" onClick={() => this.handleTogglePassword(1)} style={{ cursor: 'pointer'}} >
                              <Icon className="absolute mr-10 txt-primary" icon={this.state.icon_1} size={20}/>
                            </span>
                          </div>
                        </div>
                        <div className="form-group w-100 pb-4">
                          <label htmlFor="newpass" className="txt-primary mb-2">Nueva password:</label>
                          <div className="input-container d-flex align-items-center px-3 w-100" style={{ borderRadius: "30px", border: '1px solid gray' }}>
                            <input style={{ border: 'none' }} required="required" name="newpass" type={this.state.input_type_2} className="form-control w-100 p-0" id="pass_m_new" value={this.state.newpass} onChange={v => this.cambia('newpass', v.target.value)} aria-describedby="" />
                            <span className="flex justify-around items-center" onClick={() => this.handleTogglePassword(2)} style={{ cursor: 'pointer'}} >
                              <Icon className="absolute mr-10 txt-primary" icon={this.state.icon_2} size={20}/>
                            </span>
                          </div>
                        </div>
                        <div className="form-group w-100 pb-4">
                          <label htmlFor="renewpass" className="txt-primary mb-2">Reingrese la nueva password:</label>
                          <div className="input-container d-flex align-items-center px-3 w-100" style={{ borderRadius: "30px", border: '1px solid gray' }}>
                            <input style={{ border: 'none' }} required="required" name="renewpass" type={this.state.input_type_3} className="form-control w-100 p-0" id="pass_m_renew" value={this.state.renewpass} onChange={v => this.cambia('renewpass', v.target.value)} aria-describedby="" />
                            <span className="flex justify-around items-center" onClick={() => this.handleTogglePassword(3)} style={{ cursor: 'pointer'}} >
                              <Icon className="absolute mr-10 txt-primary" icon={this.state.icon_3} size={20}/>
                            </span>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-lg button-primary btn-contacto-enviar" id="btn-contacto-enviar">Enviar</button>
                      </>
                    </> : <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cerrar</button>
                  }
                </form>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
        <div className="container menu_login">
          <form className="form-inline" id="log_form_h_m" name="log_form_h_m" action={dest + "/externa/login.asp"} method="POST" style={{ display: 'none' }}>
            <input type="hidden" id="user" name="user" value={this.state.user} className="form-control form-control-sm user mr-1" />
            <input type="hidden" id="pass" name="pass" value={this.state.pass} className="form-control form-control-sm password mr-1" />
            <input type="hidden" id="origen" name="origen" value="new" className="form-control form-control-sm password mr-1" />
          </form>

          <form className={this.state.tipo == "agro" ? "form-inline mt-4 w-100" : "form-inline mt-4 w-90"} id="log_form" >
            {/* {this.state.error != "" &&
              <h6 className="" style={{ fontSize: "12px" }}>{this.state.error}</h6>
            } */}
            {1 === 4 &&

              <select className={`form-control form-control-sm select-blue mr-1 btn-primaryx my-2 my-sm-0 mb-2 ${this.state.tipo == "agro" ? "w-100" : "w-90"} `} value={this.state.tipo} onChange={(e) => this.cambiaTipo(e)}>
                <option value="agro">Agronegocios</option>
                <option value="financiero">Financiero</option>
                <option value="logistica">Logistica</option>
              </select>
            }
            <>
              <input type="text" id="user_mm" value={this.state.user} onChange={v => this.cambia('user', v.target.value)} className="form-control form-control-sm user w-100 mb-2 mt-2" style={{ width: "100% !important" }} aria-describedby="emailHelp" placeholder="Usuario" />
              <input type="password" id="pass_mm" value={this.state.pass} onChange={v => this.cambia('pass', v.target.value)} className="form-control  form-control-sm password w-100 mb-2" placeholder="Password" />
              {/* <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" style={{ marginTop: "10px" }} />
                <label className="form-check-label white p-2" style={{ fontSize: "12px", color: "#000" }}>Recordarme</label>
              </div> */}
              <button type="button" onClick={this.enviaFormLogin} className="btn btn-sm button-primary w-100">AGRONEGOCIOS</button>
            </>



            <button type="button" className="btn btn-sm button-primary w-100 mt-2" onClick={(e) => this.goFinanciero(e)}>MERCADO DE CAPITALES</button>
            <button type="button" className="btn btn-sm button-primary w-100 mt-2" onClick={(e) => this.goLogistica(e)}>LOGISTICA</button>
            <Link className="btn btn-sm button-secondary w-100  mt-2" to="/mercado_capitales/apertura">ABRIR CUENTA</Link>

        
            <a  className="mt-2 pt-2 w-100" style={{ borderTop: 'solid 1px #ccc' }} onMouseOver={this.logoOver} onMouseMove={this.logoMove} onMouseOut={this.logoOut}>
              <img src="https://www.allariaagro.com.ar:7510/imgs/logos/sectigo_trust_seal_lg_140x54.png" border="0"  onMouseDown={(event)=> {return window.tLKB(event,'https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=C&v_shortname=SC5&v_search=https://www.allariaagro.com.ar:7510/&x=6&y=5')}} onContextMenu={(event)=>{return window.tLLB(event);}}/>
            </a>
          </form>
        </div>

      </>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendLogin: data => {
      dispatch(sendLogin(data));
    },
    resetPass: data => {
      dispatch(resetPass(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormMenu);
