import React from "react";
import { Link, Redirect } from "react-router-dom";
import FormCv from '../containers/form_cv'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const ModCv = props => (
    <div className="site-section d-flex w-100 justify-content-center">
        <div className="box-shadow bg_white" style={{justifyContent: "center", padding: "50px 20px" }} >
          <p className="h0 blue1 uppercase">Formá parte de <br/>ALLARIA AGROFINANZAS!</p>
          <div className="w-100 text-center mt-5">
            <a className="button-primary" style={{ padding: "10px 50px"}} href="#" data-toggle="modal" data-target="#envia-cv">Envianos tu CV</a> 
          </div>
          <FormCv />
        <div/>
      </div>
    </div>    
);

export default ModCv;
