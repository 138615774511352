import React,{useEffect} from "react";
import { Link, Redirect } from "react-router-dom";
import ModNovedades from "../containers/mod_novedades";
import ModPizarras from "../containers/mod_pizarras";
import ModTermomentro from "../containers/mod_termometro";
import ModTwitter from "../containers/mod_tweets";
import ModYouTube from "../containers/mod_youtube";

const Mercados = props => {
  useEffect(() => {
    window.scrollTo(0,0)
    })
  return (
  <div>
    <div className=" fadeIn">
      <div
        className="bg_blue1 aligner p-2"
        style={{ backgroundSize: "contain" }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container text-center">
          <div className="col-12 p-4">
            <span className="h3 bold text-white">Datos del Mercado</span>
          </div>
        </div>
      </div>
    </div>
{1==3 &&

    <div className="p-4">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <p className="h6 blue2 bold mb-3">Eveniet fugit nam obcaecati</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet,
              fugit nam obcaecati fuga itaque deserunt
            </p>
          </div>
        </div>
      </div>
    </div>
}
    <div className="pt-5 pb-5 bg-light">
      <div className="container">
        <div className="col-12 mercados_cuerpo">
          <div className="row">
            <div className="col-sm-6 p-3">
              <ModPizarras />
              {/* <ModTermomentro tipo="fisico" />
              <ModTermomentro tipo="financiero" /> */}
            </div>
            <div className="col-sm-6 p-3">
              <ModYouTube />
            </div>
          </div>
          <div class="row justify-content-center align-items-center g-2">
            <div class="col-sm-12 p-3">
            <ModNovedades />
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
)
}

export default Mercados;
