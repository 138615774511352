import React from "react";
import { Link, Redirect } from "react-router-dom";

const ModAgentes = props => (
  <div className="site-section sky-blue-secondary p-md-5 logos">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-8 offset-sm-2 text-center">
          <p
            className="h3 bold white mb-5 wow fadeIn animated"
            style={{ visibility: "visible" }}
          >
            Somos agente
          </p>
          <div className="row">
            <div
              className="col-12 col-sm-6 pb-4 wow fadeInUp animated mt-3"
              data-wow-delay="0.2s"
              data-wow-duration="0.6s"
              style={{ visibility: "visible" }}
            >
              <img
                src={window.location.origin+"/imgs/logos/mr1.png"}
                alt="Mercado a Término de Buenos Aires"
              />
            </div>
            <div
              className="col-12 col-sm-6 pb-4 wow fadeInUp animated mt-3"
              data-wow-delay="0.4s"
              data-wow-duration="0.6s"
              style={{ visibility: "visible" }}
            >
              <img
                src={window.location.origin+"/imgs/logos/byma.svg"}
                alt="BYMA - Bolsas y Mercados Argentinos"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModAgentes;
