import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

const ModLineaFotos = props => {
  const [foto,setFoto] = useState( 0 );
  const pags = props.fotos.map((p,i)=>{
    return (
            <li key={"foto_"+i} className={"page-item "+(foto==i)?"active":""}>
                <a className="page-link" href="#" onClick={
                  (e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                    setFoto(i)
                  }}>{i+1}</a>
            </li>
        )
      })
      
    return (
        <div className="row">
          {
            props.fotos.map((foto, i) => (
              <div className="col-12 col-sm-6 my-3">
                <img className="image_linea_foto" src={(props.fotos && props.fotos.length>0 && props.fotos[i].archivo) ?"https://www.allariaagro.com.ar/Externa/lineatiempo/fotos/"+props.fotos[i].archivo:""} style={{width:"100%"}}/>       
                <div className="overlay_linea_foto">
                    <div className="text_linea_foto" dangerouslySetInnerHTML={ {__html:(props.fotos && props.fotos.length>0 ) ? props.fotos[i].descripcion:"" }} />
                </div>
              </div>
            ))
          }
            
{/*  
            <nav aria-label="..." className="my-2">
                <ul className="pagination pagination-sm">
                    {pags}
                </ul>
            </nav> */}
        </div>
    )
}

export default ModLineaFotos;
