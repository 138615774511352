import React from "react";
import { Link, Redirect } from "react-router-dom";

const ModCabeceraImg = props => {

    let estylo = {
        backgroundImage: "url('"+props.img+"')", height:"660px", backgroundSize:"contain"
    }

    return(
        <div
            className="stellar aligner d-flex align-items-end p-5"
            style={estylo}
            data-aos="fade"
            data-stellar-background-ratio="0.5"
        >
          <p className="h0 uppercase white">{props.titulo}</p>
        </div>
    );
}

export default ModCabeceraImg;
