import React from "react";
import moment from "moment";
export default ({ video }) => {
  
  return (
    <div>
        {1==2 &&
      <div className="d-flex w-100 justify-content-between">
        <h6 className="mb-1">{video.titulo}</h6>
        <small className="text-muted">{moment(video.fecha).format("DD.MM HH.mm")}</small>
      </div>
        }
      <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0,
          marginBottom: 15
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          // src={`https://www.youtube.com/embed/${video.id}`}
          src={"https://www.youtube.com/embed/gakl1Vd1vns?si=rk_YkqFN5jsR1zQ6"}
          frameBorder="0"
        />
      </div>
    </div>
  );
};
