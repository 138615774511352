import React, { Component } from "react";
import { connect } from "react-redux";
import { sendCv } from "../actions";
import moment from "moment"

class CvForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enviando:false,
            nombre:"",
            correo:"",
            error:"",
            archivo_cv:"",
            archivo_nombre:"",
            resp:null
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data.contador_cv != prevProps.data.contador_cv) {
            this.setState({
                enviando: false,
                resp:this.props.data.cv_resp,
                nombre:"",
                correo:""
            });
        }
    }

    onSelectFile = e => {
      if (e.target.files && e.target.files.length > 0) {
        var nombre = moment().unix()+"_"+ e.target.files[0].name
        //const reader = new FileReader();
        this.setState({ 
          archivo_cv:  e.target.files[0],
          archivo_nombre:nombre
        })
       /* reader.addEventListener("load", () =>
          this.setState({ 
            archivo_cv: reader.result,
            archivo_nombre:nombre
          })
        );
        reader.readAsDataURL(e.target.files[0]);*/
      }

  };

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n] = bstr.charCodeAt(n)
      n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
}



    enviarFormCv = ()=>{
        // VER COMO METEMOS EL ARCHIVO SI PASANDO LA UBICACION O DESDE EL ARCHIVO
        //const file = this.dataURLtoFile(this.state.archivo_cv, this.state.archivo_nombre);

        if (this.state.nombre == "") {
          this.setState({
            error: "Debe completar su Nombre"
          });
        } else if (this.state.correo == "") {
          this.setState({
            error: "Debe completar la direccion de correo"
          });
        } else if (this.validateEmail(this.state.correo) === false) {
          this.setState({
            error: "La direccion de mail es incorrecta"
          });
        
        } else {
            const data = new FormData();
            data.append("nombre", this.state.nombre);
            data.append("correo", this.state.correo);
            data.append("cvFile", this.state.archivo_cv);

            this.setState({
                error: "",
                enviando: true,
                resp:null
            });

            this.props.sendCv(data);
        }

    }

    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    cambia = (key, value) => {
      this.setState({ [key]: value });
    };

    render() {
        return(
            <div className="modal fade" id="envia-cv"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Envianos tu CV</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                          {this.state.error!="" &&
                              <h4>{this.state.error}</h4>
                          }
                          {this.state.resp==1 &&
                              <h4>SE ENVIO CORRECTAMENTE    </h4>
                          }
                          {this.state.enviando ?
                          <h3>ENVIANDO</h3>
                          :
                            <form>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="" placeholder="Nombre completo" onChange={v=>this.cambia('nombre',v.target.value)} value={this.state.nombre}/>
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" id="" aria-describedby="" onChange={v=>this.cambia('correo',v.target.value)} value={this.state.correo} placeholder="Ingresá tu email"/>
                                </div>
                                <div className="form-group">
                                    <label >Adjuntá tu CV</label>
                                    <input type="file" className="form-control-file" id="archivo" onChange={this.onSelectFile}/>
                                </div>
                            </form>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={this.enviarFormCv}>Enviar</button>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendCv: data => {
      dispatch(sendCv(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CvForm);
