import React, { Component } from "react";
import { connect } from "react-redux";
import { getVideos } from "../actions";
import YouTubeViewer from '../components/mod_youtube_viewer'
import moment from "moment";

class ModYoutube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      select: null,
      videos: []
    };
  }

  componentDidMount() {
    this.props.getVideos()
  }

  componentDidUpdate(prevProps, prevState) {
    var videos=[]
    var fecha_ini = moment("18/01/2021","DD/MM/YYYY")
    if(prevProps.data.videos!=this.props.data.videos){
      for(var v of this.props.data.videos){
        if(moment(v.fecha)>fecha_ini) videos.push(v)
      }
      this.setState({
        select: {id:"5JM5jND-SRA"},
        videos: videos
      });
    }
  }

  cambiaVideo = (v)=>{
    this.setState({select:v})
  }
  
  render() {

    const videos =(this.state.videos.length<1)? "" : this.state.videos.map((v,i)=>{
      return <a className="col-3" onClick={ ()=> this.cambiaVideo(v)} key={"v_"+i}>
          <img src={"https://img.youtube.com/vi/"+v.id+"/sddefault.jpg"} alt={v.fecha} className="img-thumbnail" key={i} />
      </a>
    })
    return (
      <div className="mb-2">
        {this.state.select &&
          <YouTubeViewer video={this.state.select} />
        }
        <div className="row">{videos}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVideos: () => {
      dispatch(getVideos());
    }
  };
};

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(ModYoutube)
