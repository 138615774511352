import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ModLineaFotos from './mod_linea_fotos'

let puerto=(window.location.origin.includes("allariaagro.com.ar"))?process.env.REACT_APP_PUERTO_HTTPS:process.env.REACT_APP_PUERTO_HTTP

const url_linea = `/datos/linea_tiempo`;

var axiosConfig = {
  crossdomain: true,
  headers: {
    Authorization: "BEARER mjb2975"
  }
};

export const getLinea = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
    const request = await axios.get(dest+url_linea, axiosConfig);
    return request.data
  } catch (error) {
  }
};

export const LineaTiempo2 = () => {

  const [linea, setLinea] = useState([])
  const [stateData, setStateData] = useState({
    anio_seleccionado: 1985,
    data: {},
    tipo: 'mgm'
  })

  const fillLinea = async () => {
    const data = await getLinea()
    setLinea(data)
  }
  
  const buscaAnio = (anio) => {
    const data = linea.filter(l => l.ano == anio)
    setStateData({
      ...stateData,
      data: data
    })
  }
  
  useEffect(() => {
    fillLinea()
    buscaAnio(1985)
  }, [])

  return (
    <>
       <nav aria-label="..." className="my-5 d-flex justify-content-center">
          <button style={{width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#E6E7E9", border: "none", marginRight: "15px"}}>1985</button>

          <div className="btn-group my-2" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-primary" style={{borderRadius: "30px 0 0 30px"}}>NOSOTROS</button>
            
            
            <button type="button" className="btn btn-secondary">ARGENTINA</button>
            
            
            <button type="button" className="btn btn-secondary" style={{borderRadius: "0 30px 30px 0"}}>MUNDO</button>
            
          </div>

          <button style={{width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#E6E7E9", border: "none", marginLeft: "15px"}} >2022</button>
        </nav>

        <div className='d-flex justify-content-center'>
          <div className="card w-100" >
            <div className="card-body p-0  p-md-3 pt-4">
                <div className="text-black container" style={{textShadow:'none'}}>
                  {
                    stateData.data && (
                      <hgroup className="row">
                        <h3 className="col text-left">{JSON.stringify(stateData.data)}</h3>
                        <h3 className="col text-right">MGM</h3>
                      </hgroup>
                    )
                  }
                  
                  <div className="btn-group my-2" role="group" aria-label="Basic example">
                    {stateData.data &&
                    <button type="button" className={(stateData.tipo=="mgm")?"btn btn-primary":"btn btn-secondary"} onClick={(e)=>this.cambiaTipo("mgm")}>MGM</button>
                    }
                    {stateData.data &&
                    <button type="button" className={(stateData.tipo=="argentina")?"btn btn-primary":"btn btn-secondary"} onClick={(e)=>this.cambiaTipo("argentina")}>ARGENTINA</button>
                    }
                    {stateData.data &&
                    <button type="button" className={(stateData.tipo=="mundo")?"btn btn-primary":"btn btn-secondary"} onClick={(e)=>this.cambiaTipo("mundo")}>MUNDO</button>
                    }
                    
                  </div>
                  <article>
                    {/* <ModLineaFotos fotos={stateData.data.mgm.fotos} /> */}
                    {/* <p style={{fontSize:"12px", height:'350px',overflow:'auto'}}>{stateData.data.mgm.descripcion}</p> */}
                  </article>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
