import React, { Component } from "react";
import { connect } from "react-redux";
import { getNovedades} from "../actions";


class ModNovedades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            pizarra:1,
            datos:[],
            nota:{},
            modal_estado:true,
            display:"none"
        };
    }

    componentDidMount() {
        this.props.getNovedades()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data.novedades != this.props.data.novedades){
            this.setState({
              loading: false,
              datos: this.props.data.novedades
            });
        }
        /*if(!this.state.loading){
            this.setState({
                loading:false
            })
        }*/
    }

    abreNovedad = (novedad) =>{
      this.setState({
        modal_estado:true,
        nota:this.state.datos[novedad],
        display:'block'
      })
    }

    cierraNovedad= ()=>{
      this.setState({
        display:'none'
      })
    }

    createMarkup = (cont) => { 
      return {__html: cont}; 
    }

    render() {
      if (this.state.loading) return <div>CARGANDO</div>

      var primera = (this.state.datos.length>0)?<a href="#" className="list-group-item list-group-item-action" style={{ boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25" }} onClick={(e)=>{
        e.preventDefault()
        this.abreNovedad(0)
      }} key={"n_0"}>
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">{this.state.datos[0].titulo}</h5>
          <small className="text-muted">{this.state.datos[0].fecha}</small>
        </div>
        <p className="mb-1" dangerouslySetInnerHTML={this.createMarkup(this.state.datos[0].copete)} />
      </a>:""

        var d = Object.assign([],this.state.datos)
        d.shift(1)

        var lista =d.map((p,i)=>{
          
          return (
            <a href="#" className="list-group-item list-group-item-action" onClick={(e)=>{
              e.preventDefault()
              this.abreNovedad(i+1)
            }} key={"n_"+(i+1)}>
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{p.titulo}</h5>
                <small className="text-muted">{p.fecha}</small>
              </div>
              <p className="mb-1" dangerouslySetInnerHTML={this.createMarkup(p.copete)} />
            </a>
          );
        })
        

        return (

          <div>
                <ul className="list-group">
                {primera}
                  {/* <a className="btn button-primary mt-4 mb-2" style={{ width: "100px", margin: "auto" }} data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">VER +  </a>
                  <div className="collapse" id="collapseExample" >
                    {lista}
                  </div> */}
                </ul>

            
            

            <div className="modal  bd-example-modal-lg " id="novedades" data-show={this.state.modal_estado} style={{display:this.state.display}}>

              <div className="modal-dialog  modal-lg  modal-dialog-scrollable" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                   { this.state.nota.titulo &&
                      <h5 className="modal-title">{this.state.nota.titulo}</h5>
                    }
                    
                    <button type="button" className="close" onClick={(e)=>{
              e.preventDefault()
              this.cierraNovedad()
            }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    { (this.state.nota.imgs && this.state.nota.imgs.lenght>0 ) &&
                      <img className="img-fluid" src={"https://www.allariaagro.com.ar/imgs/notas_media/"+this.state.nota.imgs[0].fuente} />
                    }
                    { this.state.nota.copete &&
                    <p dangerouslySetInnerHTML={this.createMarkup(this.state.nota.copete)}/>
                    }
                    { this.state.nota.texto &&
                    <p dangerouslySetInnerHTML={this.createMarkup(this.state.nota.texto)} />
                    }
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={(e)=>{
              e.preventDefault()
              this.cierraNovedad()
            }}>Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
   getNovedades: () => {
      dispatch(getNovedades());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModNovedades);
