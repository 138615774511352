import React from "react";
import { Link } from "react-router-dom";

const ModPersona = props => (

  
    <div className="col-md-6 col-lg-4 mb-5 mb-lg-5">
        <div className="team-member">
            <img src={window.location.origin+"/"+props.img} alt={props.nombre} className="img-fluid" />
            <div className="text w-100">
              <h2 className="mb-2 font-weight-light h4">{props.nombre}</h2>
              <span className="d-block mb-2 text-white-opacity-05">{props.cargo}</span>
              <p className="mb-4">{props.desc}</p>
              <p>
                {props.facebook &&
                <Link to={props.facebook} className="text-white p-2"><span className="icon-facebook"></span></Link>
                }
                {props.twitter &&
                <Link to={props.twitter} className="text-white p-2"><span className="icon-twitter"></span></Link>
                }
                {props.linkedin &&
                <Link to={props.linkedin} className="text-white p-2"><span className="icon-linkedin"></span></Link>
                }
              </p>
            </div>
        </div>
    </div>
);

export default ModPersona;
