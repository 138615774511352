import React, { useRef, useEffect,useLayoutEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ModCabeceraImg from "./mod_cabecera_img";
import ModContacto from "./mod_contacto";
import ModMapa from "./mod_mapa";
import ModEquipo from "./mod_equipo";
import ModVisionMision from "./mod_visionmision";
import ModCv from "./mod_cv";
import LineaTiempo from "../containers/linea_tiempo";
import LineaTiempo2 from "../containers/linea_tiempo2";
// import { LineaTiempo2 } from "./linea_tiempo2";
var directorio = require("../data/directorio.json");
var comerciales = require("../data/comercial.json");
var representantes = require("../data/representantes.json");
var sucursales = require("../data/sucursales.json");

const scrollToRef = ref => {
  if (ref.current) window.scrollTo(0, ref.current.offsetTop-65);
};

const Empresa = props => {
  const historiaRef = useRef(null);
  const equipoRef = useRef(null);
  const misionRef = useRef(null);
  const cvRef = useRef(null);
  const contactoRef = useRef(null);
  const mapaRef = useRef(null);

    useEffect(() => {
      if(!props.match.params.anchor){
        window.scrollTo(0,0)
      }else if(props.match.params.anchor==="equipo"){
        scrollToRef(equipoRef);
      }else if (props.match.params.anchor === "vision") {
        scrollToRef(misionRef);
      }else if (props.match.params.anchor === "historia") {
        scrollToRef(historiaRef);
      }
    });

    
  
   return (
     <div>
       {/* <img src={window.location.origin +"/imgs/equipo.jpg"} width="100%" /> */}

       <div className="site-section bg_blue1" ref={historiaRef}>
         <div className="container mb-4">
           <div className="row">
             <div className="col-10 offset-1 h5 white text-center">
               <h2 className="h3 white bold">Nuestra Historia</h2>
               <p className="h5 italic">
                Fundada en 1985 por el Lic. Carlos D. Morgan, en el año 1990 se incorpora como accionista Lucio V. García Mansilla.<br />
                Estos 36 años de historia nos consolidan como una compañía con trayectoria en el mercado, y transitando un camino de mejora continua en la permanente búsqueda de agregado de valor a la cadena comercial.<br />
                En el 2019 se incorpora como nuevo socio La Bragadense, quien comparte los mismos principios y valores que guían nuestro accionar diario.<br />
                En Febrero del 2021  Allaria Ledesma y Cia S A,  un referente en la industria de servicios financieros también ingresa en el capital accionario de la empresa.<br />
                El mercado de granos, el mercado de capitales, la administración y arbitraje del riesgo, la eficiencia logística y el orden administrativo son nuestros pilares de trabajo.
               </p>
             </div>
           </div>
         </div>
       </div>
       <div style={{display: "block", overflow: "hidden", minHeight: "755px"}}>
       {/* <LineaTiempo /> */}
       <LineaTiempo2 />
       </div>
      {/* <div ref={equipoRef}>
        <ModEquipo
         directorio={directorio}
         comerciales={comerciales}
         representantes={representantes}
         sucursales={sucursales}
       />
      </div>
      <div ref={misionRef} >
       <ModVisionMision />
      </div>
      
       <ModCv />
       <ModContacto />
       <ModMapa /> */}

     </div>
   );
};

export default Empresa;
