import React, { Component } from "react";
import ModLineaFotos from './mod_linea_fotos'

export class LineaModulo extends Component {
  constructor(props) {
    super();
    this.state = { 
      ...props.data,
      tipo: 'mgm'
    };
  }
  componentWillMount() {
   
    var dest = "mgm"
    if(this.props.data.mgm.descripcion!="" || this.props.data.mgm.fotos.lenght>0 ){
      dest = "mgm"
    }else{
      if(this.props.data.argentina.descripcion!="" || this.props.data.argentina.fotos.lenght>0){
        dest = "argentina"
      }else{
        dest = "mundo"
      }
    }
    this.setState({
      tipo:dest
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.tipo != this.props.tipo){
        this.setState({
          tipo: this.props.tipo
        });
    }
}

  cambiaTipo = (tipo)=>{
    this.setState({
      tipo:tipo
    })
  }

  createMarkup = (cont) => { 
    return {__html: cont}; 
  }

  render() {
    return (
      <div className="slide item p-2" >

      
      <div className="card w-100" >
  <div className="card-body p-0  p-md-3 pt-4">
      <div className="text-black container" style={{textShadow:'none'}}>
        <hgroup className="row">
          <h3 className="col text-left">{this.state.ano}</h3>
          {/* <h3 className="col text-right">MGM</h3> */}
        </hgroup>
        {/* <div className="btn-group my-2" role="group" aria-label="Basic example">
          {(this.props.data.mgm.descripcion!="" || this.props.data.mgm.fotos.lenght>0) &&
          <button type="button" className={(this.state.tipo=="mgm")?"btn btn-primary":"btn btn-secondary"} onClick={(e)=>this.cambiaTipo("mgm")}>MGM</button>
          }
          {(this.props.data.argentina.descripcion!="" || this.props.data.argentina.fotos.lenght>0) &&
          <button type="button" className={(this.state.tipo=="argentina")?"btn btn-primary":"btn btn-secondary"} onClick={(e)=>this.cambiaTipo("argentina")}>ARGENTINA</button>
          }
          {(this.props.data.mundo.descripcion!="" || this.props.data.mundo.fotos.lenght>0) &&
          <button type="button" className={(this.state.tipo=="mundo")?"btn btn-primary":"btn btn-secondary"} onClick={(e)=>this.cambiaTipo("mundo")}>MUNDO</button>
          }
          
        </div> */}
        <article>
          <ModLineaFotos fotos={this.state[this.state.tipo].fotos} />
          <p dangerouslySetInnerHTML={this.createMarkup(this.state[this.state.tipo].descripcion)} style={{fontSize:"12px", height:'350px',overflow:'auto'}}></p>
        </article>
        </div>
        </div>
      </div>
      </div>
    )
  }
}

export default LineaModulo;