import React, { Component } from "react";
import { connect } from "react-redux";
import { getTweets} from "../actions";
import moment from "moment";

class ModTweets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            datos:[]
        };
    }

    componentDidMount() {
        this.props.getTweets();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data.tweets != this.props.data.tweets){
            this.setState({
              loading: false,
              datos: this.props.data.tweets
            });
        }
        /*if(!this.state.loading){
            this.setState({
                loading:false
            })
        }*/
    }

    render() {
      if (this.state.loading) return <div>CARGANDO</div>
        var lista = this.state.datos.map((p,i)=>{
          return (
            <a href="#" className="list-group-item list-group-item-action" key={"n_"+i}>
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{p.text}</h5>
                <small className="text-muted">{moment(p.created_at).fromNow(true)}</small>
              </div>
            </a>
          );
        })
        return (
            <ul className="list-group">{lista}</ul>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
   getTweets: () => {
      dispatch(getTweets());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModTweets);
