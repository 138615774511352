import React, { Component } from "react";
import { NavLink, Redirect, Link } from "react-router-dom";
import LoginMod from "../containers/form_login_menu"
import LoginFormTop from "../containers/form_login_top";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticate: false,
      nombre: "",
      apellido: ""
    };
  }

  componentDidMount() { }

  componentWillMount() { }

  render() {
    let dispositivo = (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) ? true : false



    return (
      <React.Fragment>

        <div className="site-navbar">
          <LoginFormTop />

          <div className="container-fluid">
          <div className="row" d-flex align-items-center style={{ backgroundColor: "#f2f2f2"}}>

            <div className="col-3">
              <Link className="navbar-brand" to="/">
                <img
                  src={window.location.origin + "/imgs/logos/logo_allariaagro.svg"}
                  alt="Allaria Agrofinanzas S.A."
                />
              </Link>
            </div>

            <nav
              className="site-navigation text-center col-6 d-flex justify-content-center align-items-center"
              role="navigation"
            
            >


              <ul className="site-menu js-clone-nav d-none d-lg-block">
                <li>
                  {/* <NavLink to="/" style={isActive => ({ fontWeight: isActive ? "700" : "400", textDecoration: isActive ? 'underline #13387E 2px': "none", textUnderlineOffset: "8px"})}>Inicio</NavLink> */}
                  <NavLink tag={Link} className="txt-primary" exact activeClassName="underline" to="/">Inicio</NavLink>
                </li> 

                <li className="has-children">
                  {/* <NavLink to="/empresa" style={isActive => ({ fontWeight: isActive ? "700" : "400", textDecoration: isActive ? 'underline #13387E 2px': "none", textUnderlineOffset: "8px" })}>Empresa</NavLink> */}
                  <NavLink tag={Link} className="txt-primary" exact activeClassName="underline" to="/empresa/equipo">Empresa</NavLink>

                  <ul className="dropdown arrow-top txt-primary">
                    <li>
                      <NavLink to="/empresa/equipo">Equipo</NavLink>
                    </li>
                    <li>
                      <NavLink to="/empresa/historia">Historia</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/empresa/vision">Misión &amp; Visión</NavLink>
                    </li> */}
                  </ul>
                </li>

                <li className="has-children">
                  {/* <NavLink to="/servicios" style={isActive => ({ fontWeight: isActive ? "700" : "400", textDecoration: isActive ? 'underline #13387E 2px': "none", textUnderlineOffset: "8px" })}>Servicios</NavLink> */}
                  <NavLink tag={Link} className="txt-primary" exact activeClassName="underline" to="/servicios/agronegocios">Servicios</NavLink>

                  <ul className="dropdown arrow-top txt-primary">
                  <li>
                      <NavLink to="/servicios/agronegocios">Agronegocios</NavLink>
                    </li>
                    <li>
                      <NavLink to="/servicios/mercado_capitales">Mercado de Capitales</NavLink>
                    </li>
                  </ul>
                </li>
                
                {1===2&&
                  <li>
                    <NavLink to="/mercados">Clientes</NavLink>
                  </li>
                }

                <li>
                  {/* <NavLink to="/mercados" style={isActive => ({ fontWeight: isActive ? "700" : "400", textDecoration: isActive ? 'underline #13387E 2px': "none", textUnderlineOffset: "8px" })}>Datos del Mercado</NavLink> */}
                  <NavLink tag={Link} className="txt-primary" exact activeClassName="underline" to="/mercados">Datos del Mercado</NavLink>

                </li>

                <li>
                  {/* <NavLink to="/contacto" style={isActive => ({ fontWeight: isActive ? "700" : "400", textDecoration: isActive ? 'underline #13387E 2px': "none", textUnderlineOffset: "8px" })}>Contacto</NavLink> */}
                  <NavLink tag={Link} className="txt-primary" exact activeClassName="underline" to="/contacto">Contacto</NavLink>

                </li>
              </ul>
            </nav>
            <div className="space col-3 d-flex justify-content-end">
              <div className="d-inline-block d-lg-none ml-auto py-3">
                <a
                  href="#"
                  className="site-menu-toggle js-menu-toggle"
                >
                  <span className="icon-menu h2"></span>
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="site-mobile-menu">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body">
            <LoginMod />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
