import React from "react";
import { Link, Redirect } from "react-router-dom";

const ModIntro = props => (
  <div className="site-section p-0">
    <div className="container mb-4">
      <div className="row">
        <div className="col-10 italic txt-primary m-auto text-center">
          <img className="m-5" src={window.location.origin+"/imgs/icons/chevron.png"} alt="" />
          <p style={{ fontSize: "25px", fontWeight: "400", lineHeight: "1.3", margin: "0"}}>
            Somos un equipo con sólidos valores, que brinda servicios
            profesionales, defendiendo la transparencia del mercado, aportando
            conocimiento <br/> y tecnología a nuestros clientes.
          </p>
          <Link className="btn button-primary m-5" to="empresa/equipo">
            ¡Conocé más sobre nosotros!
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default ModIntro;
