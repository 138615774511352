import React, { Component } from "react";
import { connect } from "react-redux";
import { getLinea} from "../actions";
import LineaModulo from "../components/linea_modulo"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class LineaTiempo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            pizarra:1,
            datos:[],
            ano:0,
            responsive:{
              0: {
                  items: 1,
              },
              450: {
                  items: 1,
              },
              650: {
                  items: 2,
              },
              900: {
                  items: 3,
              }
            }
        }
    }

    componentDidMount() {
        this.props.getLinea()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data.linea_tiempo != this.props.data.linea_tiempo){
            this.setState({
              loading: false,
              datos: this.props.data.linea_tiempo
            });
        }
        
    }

    setAno = (pos) =>{
      this.setState({
        ano:pos
      })
    }

    cambioAno = (e)=>{
    }

    render() {
      if (this.state.loading) return <div>CARGANDO</div>
        var nav_arr =  this.state.datos.map((p,i)=>{
           return <li key={"linea_nav_"+i} className={(this.state.ano==i)?"page-item active":"page-item"}>
            <a className="page-link" href="#" onClick={
                (e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                    this.setAno(i)
                }}>{p.ano}</a>
        </li>
        })
        var lista = this.state.datos.map((p,i)=>{
          return (
            <LineaModulo data={p} key={"linea_"+i}/>
          );
        })
        return (
           <div style={{display:'block',overflow:'hidden',minHeight:"670px"}}>

               <nav aria-label="..." className="col-12  my-2 text-center">
                      <ul className="pagination pagination-sm flex-wrap justify-content-center">
                          {nav_arr}
                      </ul>
                  </nav>

             
               <OwlCarousel
                    className="owl-theme owl-carousel owl-carousel-lineatiempo box white text-center shade wow fadeIn"
                    loop={false}
                    items={3}
                    margin={0}
                    center={false}
                    startPosition={this.state.ano}
                    responsive={this.state.responsive}
                    onChanged={this.cambioAno}
                    //navText={"<div class='nav-btn prev-slide'>-</div>","<div class='nav-btn next-slide'>+</div>"}
                   // autoplay={true}
                   dots={false}
                    nav
                  >
                     {lista} 

                  </OwlCarousel>
                  
           </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
   getLinea: () => {
      dispatch(getLinea());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineaTiempo);