import React from 'react';

const ModFatca = () => {
    return (
        <div className="modal" id="fatca" role="dialog">
            <div className="modal-dialog mw-100 w-75" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="h4 bold blue2 mb-4">Acuerdo FATCA</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-9">
                                <p className="h5 italic mb-5 blue1">Allaria Agrofinanzas. S.A. firmó el acuerdo FATCA (Foreign Account Tax Compliance Act) con el IRS, en virtud del cual debemos obtener y verificar cierta información de nuestros clientes, reportar al IRS determinada información de clientes americanos y eventualmente realizar retenciones.
                                <br/><br/>
                                FATCA requiere que revisemos nuestros procesos vigentes de aceptación de clientes y procesos de retenciones y que los ajustemos en función de las regulaciones, en la medida que corresponda.</p>
                                </div>
                                <div className="col-12 col-md-3 mt-5">
                                <a className="btn btn-outline-primary m-2 w-100" href="https://www.allariaagro.com.ar/PDF/comunicacion-clientes-fatca.pdf" target="_blank">Comunicación a clientes</a>
                                <a className="btn btn-outline-primary m-2 w-100" href="https://www.allariaagro.com.ar/PDF/comunicacion-contrapartes-fatca.pdf" target="_blank">Comunicación a contrapartes y bancos corresponsales</a>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModFatca;