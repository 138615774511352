import React from 'react';

const ModOcde = () => {
    return (
        <div className="modal" id="ocde" role="dialog">
            <div className="modal-dialog mw-100 w-75" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="h4 bold blue2 mb-4">Acuerdo OCDE</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-9">
                                    <h3 className="h5 blue2 mb-4">
                                        ¿QUÉ ES EL ACUERDO CRS DE OCDE?
                                    </h3>
                                    <p className="h5 italic mb-5 blue1">
                                        Es un acuerdo de estándar de reporte común (CRS, por sus siglas en inglés) para el intercambio
                                        automático de información tributaria firmado por 93 países (incluida la República Argentina) en el marco
                                        de la Organización para la Cooperación y el Desarrollo Económico. En nuestro país fue implementado a
                                        través de la Resolución General Nº 3826/2015 de la AFIP, actualmente regido por la Resolución General
                                        Nº 4056/2017 de la AFIP, y modificatorias.
                                    </p>
                                    <h3 className="h5 blue2 mb-4">
                                        ¿CUÁL ES EL OBJETIVO DEL ACUERDO CRS DE OCDE FIRMADO POR LA
                                        REPÚBLICA ARGENTINA?
                                    </h3>
                                    <p className="h5 italic mb-5 blue1">
                                        El objetivo del Acuerdo OCDE es el de intensificar a nivel global la lucha contra la evasión fiscal.
                                    </p>
                                    <h3 className="h5 blue2 mb-4">
                                        ¿A QUIÉN IMPACTA EL ACUERDO CRS DE OCDE?
                                    </h3>
                                    <p className="h5 italic mb-5 blue1">
                                        El Acuerdo OCDE tiene un impacto sobre todas las Personas Humanas y Jurídicas sujetas al pago del
                                        impuesto a las ganancias en los 93 países firmantes del acuerdo.
                                    </p>
                                    <h3 className="h5 blue2 mb-4">
                                        ¿CÓMO IMPACTA EL ACUERDO CRS DE OCDE A LOS CLIENTES DE ALLARIA AGROFINANZAS S.A.?
                                    </h3>
                                    <p className="h5 italic mb-5 blue1">
                                        A partir del 1º de enero de 2016 los clientes nuevos (Personas Humanas y Jurídicas) deberán declarar si
                                        tienen residencia fiscal en el exterior, y en el caso de las Personas Jurídicas deberán además declarar si
                                        tienen controlantes (beneficiarios finales con más del 25% de control de la sociedad) que sean residentes
                                        fiscales en el exterior.
                                    </p>
                                    <p className="h5 italic mb-5 blue1">
                                        Por otra parte, la Entidad deberá identificar clientes existentes al 31 de diciembre de 2015 con residencia
                                        fiscal en el exterior, o (en el caso de las Personas Jurídicas) también con controlantes con residencia fiscal
                                        en el exterior. En ese contexto, es posible que algunos clientes sean contactados para firmar una
                                        Declaración Jurada OCDE.
                                    </p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModOcde;