import {
  GET_DIRECTORIO,
  GET_TERMOMETRO,
  GET_TERMOMETRO_BOLSAR,
  GET_PIZARRAS,
  GET_NOVEDADES,
  GET_MENCIONES,
  GET_TWEETS,
  SEND_CONTACTO,
  SEND_CV,
  SEND_LOGIN,
  GET_VIDEOS,
  ABRE_MODAL,
  CIERRA_MODAL,
  GET_LINEA,
  RESET_PASS
} from "../actions";

import { fromJS } from 'immutable';


const initialTodos = {
  directorio: [],
  novedades: [],
  menciones:[],
  pizarras: [],
  termometro: [],
  termometro_bolsar: [],
  tweets: [],
  videos: [],
  linea_tiempo: [],
  contador_contacto: 0,
  contador_cv: 0,
  cv_resp:null,
  contador_login: 0,
  respuesta_login:"",
  contador_login_m: 0,
  respuesta_login_m:"",
  reset_pass: ""
};

const data = (state = initialTodos, action) => {

    var newState = Object.assign({}, state);
    const datos = fromJS(newState)
    switch (action.type) {
      case GET_DIRECTORIO:
        const momentoNew = datos.updateIn(["momento"], value => action.datos);
        const momentoNew2 = momentoNew.updateIn(["msj"], value => "");
        return momentoNew2.toJS();

      case GET_NOVEDADES:
        const novedadesNew = datos.updateIn(
          ["novedades"],
          value => action.payload.data
        );
        return novedadesNew.toJS();

        case GET_MENCIONES:
          const mencionesNew = datos.updateIn(
            ["menciones"],
            value => action.payload.data
          );
          return mencionesNew.toJS();
      case GET_TWEETS:
        const tweetsNew = datos.updateIn(
          ["tweets"],
          value => action.payload.data
        );
        return tweetsNew.toJS();

      case GET_PIZARRAS:
        const pizarrasNew = datos.updateIn(
          ["pizarras"],
          value => action.payload.data.pizarras
        );
        return pizarrasNew.toJS();


      case GET_LINEA:
          const lineaNew = datos.updateIn(
            ["linea_tiempo"],
            value => action.payload.data
          );
          return lineaNew.toJS();

      case GET_TERMOMETRO:
        const termometroNew = datos.updateIn(
          ["termometro"],
          value => action.payload.data
        );
        return termometroNew.toJS();

      case GET_VIDEOS:
        const videosLista = action.payload.data.items.map(v=>{
          return {
            id: v.id.videoId,
            titulo: v.snippet.title,
            fecha: v.snippet.publishedAt
          };
        })
        const videosNew = datos.updateIn(["videos"], value => videosLista);
        return videosNew.toJS();

      case GET_TERMOMETRO_BOLSAR:
        const termometroBolsarNew = datos.updateIn(
          ["termometro_bolsar"],
          value => action.payload.data
        );
        return termometroBolsarNew.toJS();

      case SEND_CONTACTO:
        var newCounter = newState.contador_contacto + 1;
        const contactoNew = datos.updateIn(
          ["contador_contacto"],
          value => newCounter
        );
        return contactoNew.toJS();

      case SEND_CV:
        var newCounter = newState.contador_cv + 1;
        const cvNew = datos.updateIn(["contador_cv"], value => newCounter);
        const cvRespNew = cvNew.updateIn(["cv_resp"], value => action.payload.data.resp);
        return cvRespNew.toJS();

      case SEND_LOGIN:
        var newCounter = (action.f==2)?newState.contador_login_m + 1:newState.contador_login + 1;
        const loginNew = datos.updateIn(["contador_login"+((action.f==2)?"_m":"")],value => newCounter);
        const loginRespNew = loginNew.updateIn(["respuesta_login"+((action.f==2)?"_m":"")],value => action.payload);
        const loginRespNew2 = loginRespNew.updateIn(["vencimiento"],value => action.payload);
        const loginRespNew3 = loginRespNew2.updateIn(["bloqueado"],value => action.payload);
        return loginRespNew3.toJS();
      
      case RESET_PASS:
        const newResetPass = datos.updateIn(["reset_pass"],value => action.payload);
        return newResetPass.toJS();

      default:
        return state;
    }
}

export default data