import React, { Component } from "react";
import { connect } from "react-redux";
import { getLinea} from "../actions";
import LineaModulo from "../components/linea_modulo"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useForm } from './../hooks/useForm'

class LineaTiempo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      pizarra:1,
      datos:[],
      ano: "",
      default: {
        ano: 1985,
        argentina: {
          descripcion: "Se pone en marcha el Plan Austral <br>En nuestro país atravesábamos un año muy difícil: retenciones del 35% y una brecha cambiaria del 30%.<br>La Junta Nacional de Granos hacía que el mercado, que además no permitía operar en dólares sino sólo en australes, estuviese muy controlado.<br>Se producen en nuestro país 40 millones de toneladas anuales de granos, con los siguientes rindes: maíz 3,8 toneladas por hectárea; soja 2,2 toneladas por hectárea y trigo 1,8 toneladas por hectárea.<br>Además, el área sembrada presenta la siguiente distribución: maíz y soja 3,8 millones de hectáreas respectivamente, trigo 5,8 millones de hectáreas.",
          fotos: [
            { archivo: "FOTO2planaustral.jpg", descripcion: "Se pone en marcha el Plan Austral"},
            { archivo: "79.jpg", descripcion: ""},
            { archivo: "80.jpg", descripcion: ""},
            { archivo: "81.jpg", descripcion: ""},
          ]
        },
        mgm: {
          descripcion: "Año de constitución de la empresa, que llevaba el nombre de Carlos Morgan S.A.<br><br>En los inicios confié plenamente y conté con el apoyo de familiares y amigos, quienes conocían mi trayectoria laboral en otras actividades…<br>Comenzamos con un equipo de trabajo muy pequeño: Daniel Fernández se encontraba a cargo de la parte administrativa del negocio y Luis Pineda, quien inicialmente me acompañaba a la Bolsa y además brindaba soporte a Daniel. Posteriormente Daniel&nbsp; me reemplaza como operador de futuros y opciones, actividad que desempeñó con gran profesionalismo…<br>…Recuerdo el primer contrato que firmó nuestra naciente empresa: se realizó con la firma Quiet S.A., empresa que nos confió un camión de girasol que fue vendido a Molinos Río en Avellaneda. Sentimos que la máquina comenzaba a funcionar… <br>Carlos D. Morgan, Socio Fundador de Morgan, García Mansilla y Cía. S.A.",
          fotos: [
            { archivo: "84.jpg", descripcion: "Familia de Carlos D. Morgan, su principal sostén y motor en los inicios."},
            { archivo: "FOTO1carlosmorgan.JPG", descripcion: ""},
          ]
        },
        mundo: {
          descripcion: " Toma del Palacio de Justicia en Bogotá. <br>La erupción del Nevado del Ruiz en Colombia provocó la muerte de más de 24.000 personas. <br>Detección del agujero de la capa de ozono en la Antártida. <br>Un terremoto en la ciudad de México acabó con la vida de más de 35.000 personas. <br>Terremoto en la zona central de Chile, con una magnitud de 7,7 en la escala de richter, dejando un total de 177 muertos. ",
          fotos: [
            { archivo: "FOTO3tomapalacio.jpg", descripcion: "Toma del Palacio de Justicia en Bogotá"},
          ]
        }
      },
      data_ano: [],
      tipo: 'mgm',
      responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 1,
        },
        650: {
            items: 2,
        },
        900: {
            items: 3,
        }
      }
    }
  }

    componentDidMount() {
        this.props.getLinea()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data.linea_tiempo != this.props.data.linea_tiempo){
            this.setState({
              loading: false,
              datos: this.props.data.linea_tiempo
            });
        }
    }

    setAno = (pos) =>{
      // pos+">"+this.state.ano)

      this.setState({
        ano:pos
      })
    }

    cambioAno = (e)=>{
    }

    onInputChange = (e) => {
      this.setState({
        ano: e.target.value,
        tipo: 'mgm'
      })
    }

    onChangeStart = () => {
      this.setState({
        ano: 1985,
        tipo: 'mgm'
      })

    }

    nextYear = () => {
      this.setState({
        ano: this.state.ano != "" ? this.state.ano + 1 : 1986,
        tipo: this.state.tipo
      })

    }

    previousYear = () => {
      if(this.state.ano == "" || this.state.ano == 1985) return
      this.setState({
        ano: this.state.ano - 1,
        tipo: this.state.tipo
      })
    }

    onChangeLast = () => {
      this.setState({
        ano: this.state.datos[this.state.datos.length -1].ano
      })
    }

    cambiaTipo = (tipo)=>{
      this.setState({
        tipo:tipo
      })
    }

    render() {
      if (this.state.loading) return <div>CARGANDO</div>
      var nav_arr =  this.state.datos.map((p,i)=>{
          return <li key={"linea_nav_"+i} className={(this.state.ano==i)?"page-item active":"page-item"}>
          <a className="page-link" href="#" onClick={
            (e)=>{
                e.preventDefault()
                e.stopPropagation()
                this.setAno(i)
            }}
          >
            {p.ano}
          </a>
      </li>
      })

      var lista = this.state.datos.map((p,i)=>{
        if(p.ano == this.state.ano) {
          return (
            <LineaModulo data={p} key={"linea_"+i} tipo={this.state.tipo || 'mgm'} />
          );
        }
      })

      return (
        <div style={{display:'block',overflow:'hidden',minHeight:"670px"}}>

          <nav aria-label="..." className="mt-5 mb-3 d-flex justify-content-center">
                {/* <ul className="pagination pagination-sm flex-wrap justify-content-center">
                    {nav_arr}
                </ul> */}

              <button className="btn" onClick={(e) => this.previousYear()}>
                <i class="fas fa-chevron-left mr-2"></i>
              </button>

                <div className="button-year d-flex justify-content-center align-items-center mr-3" style={{width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#E6E7E9", border: "none"}}
                onClick={this.onChangeStart}>{this.state.datos[0].ano}</div>

                <div className="btns-linea-tiempo btn-group my-2" role="group" aria-label="Basic example">
                  <button type="button" className={ this.state.tipo == "mgm" ? 'btn button-primary btn-mgm' : 'btn button-no-selected'} style={{borderRadius: "30px 0 0 30px"}}  onClick={(e)=>this.cambiaTipo("mgm")}>NOSOTROS</button>
                  
                  
                  <button type="button" className={ this.state.tipo == "argentina" ? 'btn button-primary border' : 'btn button-no-selected border'} onClick={(e)=>this.cambiaTipo("argentina")}>ARGENTINA</button>
                  
                  
                  <button type="button" className={ this.state.tipo == "mundo" ? 'btn button-primary' : 'btn button-no-selected'} style={{borderRadius: "0 30px 30px 0"}} onClick={(e)=>this.cambiaTipo("mundo")}>MUNDO</button>
                  
                </div>

                <button className="btn" onClick={(e) => this.nextYear()}>
                  <i class="fas fa-chevron-right ml-2"></i>
                </button>

                <div className="button-year button-year-desktop justify-content-center align-items-center ml-3" style={{width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#E6E7E9", border: "none"}}  onClick={this.onChangeLast}>{this.state.datos[this.state.datos.length -1].ano}</div>
          </nav>

          <div className="search d-flex justify-content-center align-items-center">
            <div className="search-year text-secondary">
              <i class="fas fa-search"></i>
              <input type="text" placeholder="Buscar por año" onChange={this.onInputChange} value={this.state.ano} className="border-none"/>
            </div>
            <div className="button-year button-year-mobile justify-content-center align-items-center ml-3" style={{width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#E6E7E9", border: "none"}}  onClick={this.onChangeLast}>{this.state.datos[this.state.datos.length -1].ano}</div>
          </div>
         
          <div className="d-flex justify-content-center align-items-center mt-3">

            
          </div>

          { (this.state.ano.length < 4 && lista)  ? (
            <div className="text-center my-2">
              <LineaModulo data={this.state.default} tipo={this.state.tipo || 'mgm'} />
            </div>) : lista}

        </div>
      );
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
   getLinea: () => {
      dispatch(getLinea());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineaTiempo);
