import React from "react";
import { Link, Redirect } from "react-router-dom";

const ModMapa = props => (
  <div id="mod-mapa" className="">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.1589016582707!2d-58.37454738469409!3d-34.600143180460414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccaccb1e719e3%3A0x93441d62cff4e9d8!2sReconquista%20661%2C%20C1054%20CABA!5e0!3m2!1sen!2sar!4v1570059272534!5m2!1sen!2sar"
      width="100%"
      height="413"
      frameBorder="0"
      style={{border:0}}
      allowFullScreen=""
    ></iframe>
  </div>
);

export default ModMapa;
