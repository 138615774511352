import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const ModVisionMision = props => {
    return (
              <div className="sky-blue-secondary mt-5">
                <div className="d-flex justify-content-center">
                  <div
                    className="text-center pt-5"
                    style={{
                      position: "absolute",
                      width: "auto",
                      margin: "auto",
                      left: 0,
                      right: 0,
                      zIndex: 100
                    }}
                  >
                    <h4 className="h5 bold white">
                      Nuestros Valores
                    </h4>
                  </div>

                  <OwlCarousel
                    className="owl-theme owl-carousel box white text-center wow fadeIn marquesina_valores"
                    loop
                    items={1}
                    margin={0}
                    center={true}
                    autoplay={true}
                    // nav
                    style={{ marign: "auto" }}
                  >
                    <div className="slide item d-flex justify-content-center align-items-center flex-column">
                        <p className="header-1 pb-3">
                          Honestidad y Transparencia
                        </p>
                        <p className="header-5">
                          Hacemos negocios de manera honesta, con la verdad y de
                          forma clara y sustentable.
                        </p>
                    </div>

                    <div className="slide item d-flex justify-content-center align-items-center flex-column">
                        <p className="header-1 pb-3">Coherencia</p>
                        <p className="header-5">
                          Existe una línea de conducta que expresa simetría
                          entre lo que decimos y hacemos. Hacemos lo que
                          decimos.
                        </p>
                    </div>

                    <div className="slide item d-flex justify-content-center align-items-center flex-column">
                        <p className="header-1 pb-3">
                          Actitud positiva y Compromiso
                        </p>
                        <p className="header-5">
                        Queremos ser personas con actitud positiva, que enfrentan los problemas y buscan soluciones. La actitud de servicio es parte de nuestro compromiso diario.
                        </p>
                    </div>
{1===3 &&

                    <div className="slide item d-flex justify-content-center align-items-center flex-column">
                        <p className="h2 bold pb-3">
                          Honestidad y Transparencia
                        </p>
                        <p>
                          Queremos ser personas con actitud positiva, que
                          enfrenta los problemas y busca soluciones. La actitud
                          de servicio es parte de nuestro compromiso diario.
                        </p>
                    </div>
}
                    <div className="slide item d-flex justify-content-center align-items-center flex-column">
                        <p className="header-1 pb-3">Profesionalismo</p>
                        <p className="header-5">
                          Aplicamos las mejores prácticas, ancladas en valores,
                          con el ejercicio de la mejora continua.
                        </p>
                    </div>

                    <div className="slide item d-flex justify-content-center align-items-center flex-column">
                        <p className="header-1 pb-3">Respeto</p>
                        <p className="header-5">
                        Respetamos a las personas, las instituciones y las normas.
                        </p>
                    </div>

                  </OwlCarousel>
                </div>
              </div>
    );
}


export default ModVisionMision;
