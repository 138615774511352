import React from "react";
import { Link, Redirect } from "react-router-dom";
import ModPersona from "./mod_equipo_persona";
import _ from "lodash"

const ModEquipo = props => {
    const directorio = props.directorio.map((usuario,i) => {
        return (
          <ModPersona
            nombre={usuario.nombre}
            cargo={usuario.cargo}
            desc={usuario.desc}
            img={usuario.img}
            facebook={usuario.facebook}
            twitter={usuario.twitter}
            linkedin={usuario.linkedin}
            key={i}
          />
        );
    })
    const representantes = props.representantes.map((rep,i) => {
        return <li key={"r_" + i}>{rep.nombre}</li>;
    })

    const sucursales = props.sucursales.map((suc,i) => {
        return (
            <>
                <li key={"r_" + i}>{suc.nombre}</li>
                <p>Domicilio: {suc.domicilio}</p>
            </>
        );
    })
    
    const [c1, c2] = _.chunk(props.comerciales, _.round(props.comerciales.length / 2));
    const com_1 = c1.map((com,i) => {
        return <li key={"a_" + i}>{com.nombre}</li>;
    })
    const com_2 = c2.map((com, i) => {
      return <li key={"b_" + i}>{com.nombre}</li>;
    });

    return (
        <div className="site-section pt-3">
            <div className="container" data-aos="fade-up">

            <div className="p-4">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="team-memberx">
                            <p className="h2 blue1">Equipo</p>
                            <p className="h4 blue2 bold mb-4">Comité de Gestión</p>
                            <p></p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

                <div className="row d-flex justify-content-center">
                    {directorio}
                </div>

                <hr/>
      
                <div className="row pt-4">
                    <div className="col-md-6 col-lg-4">
                        <p className="h4 bold blue2 mb-4">Equipo Comercial</p>
                        <p></p>
                    </div>
                    <div className="col-md-6 col-lg-8 mb-1 mb-md-5">
                        <div className="row">
                            <div className="col-6 mb-1">
                                <ul className="h6 italic blue1 list_equipo">
                                {com_1}
                                </ul>
                            </div>
                            <div className="col-6">
                                <ul className="h6 italic  blue1 list_equipo">
                                 {com_2}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
      
                <hr/>
      
                <div className="row pt-4">
                    <div className="col-md-6 col-lg-4">
                        <p className="h4 bold blue2 mb-4">Sucursales</p>
                    </div>
                    <div className="col-md-6 col-lg-8 mb-1 mb-md-5">
                        <div className="row">
                            <div className="col-12 mb-1">
                                <ul className="h6 italic blue1 list_equipo">
                                {sucursales}
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>


                <hr/>
      
                <div className="row pt-4">
                    <div className="col-md-6 col-lg-4">
                        <p className="h4 bold blue2 mb-4">Agentes</p>
                    </div>
                    <div className="col-md-6 col-lg-8 mb-1 mb-md-5">
                        <div className="row">
                            <div className="col-6 mb-1">
                                <ul className="h6 italic blue1 list_equipo">
                                {representantes}
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ModEquipo;
