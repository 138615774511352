import React from "react";
import {
  Link,
  Redirect,
} from "react-router-dom";

const Footer = props => (
  <>
    <footer className="site-footer background-primary px-5 py-4 container-fluid">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-3 d-flex align-items-center flex-column">
          <img
            src={window.location.origin+"/imgs/logos/logo_allaria_blanco.svg"}
            alt="Allaria Agrofinanzas S.A."
            className="mb-4"
            style={{ width: "90%", maxWidth: "240px" }}
          />
          <p className="text-white">
            Agente de Negociación registrado bajo el N°220 de la CNV. 
          </p>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-4">
          <div className="d-flex flex-row align-items-cente  align-self-md-start">
            <div className="d-flex flex-column m-4">
              <p className="bold"><Link className="white" to="/empresa">Empresa</Link></p>
              <ul>
                <li><Link className="white" to="/empresa/equipo">Equipo</Link></li>
                <li><Link className="white" to="/empresa/historia">Historia</Link></li>
              </ul>
            </div>

            <div className="d-flex flex-column m-4">
              <p className="bold"><Link className="white" to="/agronegocios">Servicios</Link></p>
              <ul>
                <li><Link className="white" to="/agronegocios">Agronegocios</Link></li>
                <li><Link className="white" to="/mercado_capitales">Mercado de Capitales</Link></li>
              </ul>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row align-self-start">
            <p className="bold m-4"><Link className="white" to="/mercados">Datos de Mercado</Link></p>

            <p className="bold m-4"><Link className="white" to="/">Login de Clientes</Link></p>
          </div>
        </div>
        
        <div className="col-sm-3 text-center d-flex justify-content-center">
          <a href="https://twitter.com/allariaagro" className="mx-2" target="_blank">
            <img src={window.location.origin+"/imgs/icons/x.png"} alt="" style={{ width: "30px" }}/>
          </a>
          <a href="https://www.linkedin.com/company/allaria-agrofinanzas/" className="mx-2" target="_blank">
            <img src={window.location.origin+"/imgs/icons/linkedin.png"} alt="" style={{ width: "30px" }}/>
          </a>
          <a href="https://www.instagram.com/allariaagro" className="mx-2" target="_blank">
            <img src={window.location.origin+"/imgs/icons/instagram.png"} alt="" style={{ width: "30px" }}/>
          </a>
          <a href="https://www.youtube.com/@AllariaAgrofinanzastv" className="mx-2" target="_blank">
            <img src={window.location.origin+"/imgs/icons/youtube.png"} alt="" style={{ width: "30px" }}/>
          </a>
        </div>
      </div>
    </footer>

    <footer className="site-footer-mobile background-primary px-5 mt-4 py-4">
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex align-items-center flex-column">
          <img
            src={window.location.origin+"/imgs/logos/logo_allaria_blanco.svg"}
            alt="Allaria Agrofinanzas S.A."
            className="mb-4"
            style={{ width: "90%", maxWidth: "240px" }}
          />
          <p className="text-white">
            Agente de Negociación
            <br/>
            registrado bajo
            <br/>
            el N°220 de la CNV.
          </p>
        </div>

        <div className="text-center redes-mobile d-flex mt-3 py-3 align-self-center">
          <a href="https://twitter.com/allariaagro" className="mx-3" target="_blank">
            <img src={window.location.origin+"/imgs/icons/x.png"} alt=""/>
          </a>
          <a href="https://www.linkedin.com/company/allaria-agrofinanzas/" className="mx-3" target="_blank">
            <img src={window.location.origin+"/imgs/icons/linkedin.png"} alt=""/>
          </a>
          <a href="https://www.instagram.com/allariaagro" className="mx-3" target="_blank">
            <img src={window.location.origin+"/imgs/icons/instagram.png"} alt=""/>
          </a>
          <a href="https://www.youtube.com/@AllariaAgrofinanzastv" className="mx-3" target="_blank">
            <img src={window.location.origin+"/imgs/icons/youtube.png"} alt=""/>
          </a>
        </div>

        <div className="d-flex flex-column ml-4 pt-4">
          <div className="d-flex flex-column links-mobile">
            <p className="bold my-2"><Link className="white" to="/mercados">Datos de Mercado</Link></p>

            <p className="bold my-2"><Link className="white" to="/">Login de Clientes</Link></p>
          </div>

          <div className="d-flex flex-row footer-datos links-mobile justify-content-between">
            <div className="d-flex flex-column my-2">
              <p className="bold"><Link className="white" to="/empresa">Empresa</Link></p>
              <ul>
                <li><Link className="white" to="/empresa/equipo">Equipo</Link></li>
                <li><Link className="white" to="/empresa/historia">Historia</Link></li>
              </ul>
            </div>

            <div className="d-flex flex-column my-2 mx-4">
              <p className="bold"><Link className="white" to="/agronegocios">Servicios</Link></p>
              <ul>
                <li><Link className="white" to="/agronegocios">Agronegocios</Link></li>
                <li><Link className="white" to="/mercado_capitales">Mercado de Capitales</Link></li>
              </ul>
            </div>
          </div>

        </div>
        
      </div>
    </footer>
  </>
);

export default Footer;
