import React,{useState, useEffect,useLayoutEffect} from "react";
import { Link, Redirect } from "react-router-dom";
import ModAgentes from './mod_agentes'
import ModApp from "./mod_apps";
import ModContacto from "./mod_contacto";
import ModMapa from "./mod_mapa";
import ModServicios from "./mod_servicios";
import ModMarquee from "./mod_marquee";
import ModIntro from "./mod_intro";
import ModTitulo from "./mod_titulo";
import ModLogin from './mod_login'

const Home = props =>{

  const [cargo, set_cargo] = useState(false)

  useEffect(() => {
    window.scrollTo(0,0)
  })

  useLayoutEffect(() => {
    set_cargo(true)
  }, [])

  return   (
  <div>
    {/* <ModLogin /> */}
    <ModMarquee />
    <ModIntro />
    <ModApp />
    <ModAgentes />
    <ModTitulo titulo="Servicios Integrales" />
    <ModServicios />
    <ModContacto />
   
  </div>
)
};

export default Home;
