import React from "react";
import { Link, Redirect } from "react-router-dom";


const ModTitulo = props => (
  <div className="site-section p-md-5 text-center" style={{background:"#f8f8f8"}}>
    <p className="h2 bold mb-5 p-md-5 wow fadeIn animated"  style={{visibility: "visible"}}  >
      {props.titulo}
    </p>
  </div>
);

export default ModTitulo;
