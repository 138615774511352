import React, { Component } from "react";
import { connect } from "react-redux";
import { sendContacto } from "../actions";


class ContactoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enviando:false,
            nombre:"",
            correo:"",
            asunto:"",
            msj:"",
            error:""
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.data.contador_contacto != prevProps.data.contador_contacto) {
        this.setState({
          enviando: false,
          nombre:"",
            correo:"",
            asunto:"",
            mensaje:"",
        });
      }
    }

    enviaFormContacto = () =>{
      if (this.state.nombre == "") {
        this.setState({
          error: "Debe completar su Nombre"
        });
      } else if (this.state.correo == "") {
        this.setState({
          error: "Debe completar la direccion de correo"
        });
      } else if (this.validateEmail(this.state.correo) === false) {
        this.setState({
          error: "La direccion de mail es incorrecta"
        });
      } else if (this.state.asunto == "") {
        this.setState({
          error: "Debe completar el asunto"
        });
      } else if (this.state.mensaje == "") {
        this.setState({
          error: "Debe completar el mensaje"
        });
      } else {
       
        const sendData = new FormData();
        sendData.append("nombre", this.state.nombre);
        sendData.append("correo", this.state.correo);
        sendData.append("asunto", this.state.asunto);
        sendData.append("mensaje", this.state.mensaje);

        this.setState({
          error: "",
          enviando: true
        });

        this.props.sendContacto(sendData);
      }
    }
    cambia = (key, value) => {
      this.setState({ [key]: value });
    };

    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    render() {
        return(
            <div id="form-contacto" className="">
                {this.state.error!="" &&
                    <h4>{this.state.error}</h4>
                }
                {this.state.enviando ?
                <h3>ENVIANDO</h3>
                :
                <form className="" method="post" onSubmit={this.enviaFormContacto}>
                  <div className="form-group">
                    <input style={{ borderRadius: "30px" }} required="required" name="c[nombre_y_apellido]" type="text" className="form-control" onChange={v=>this.cambia('nombre',v.target.value)} value={this.state.nombre} id="" aria-describedby="" placeholder="Nombre"/>
                  </div>
                  <div className="form-group">
                    <input style={{ borderRadius: "30px" }} required="required" name="c[email]" type="email" className="form-control" onChange={v=>this.cambia('correo',v.target.value)} value={this.state.correo} id="" aria-describedby="" placeholder="Email"/>
                  </div>
                  <div className="form-group">
                    <input style={{ borderRadius: "30px" }} name="c[asunto]" type="text" className="form-control" onChange={v=>this.cambia('asunto',v.target.value)} value={this.state.asunto} id="" aria-describedby="" placeholder="Asunto"/>
                  </div>
                  <div className="form-group">
                    <textarea style={{ borderRadius: "30px" }} name="c[mensaje]" className="form-control" onChange={v=>this.cambia('mensaje',v.target.value)} rows="3" id="mensaje" placeholder="">{this.state.mensaje}</textarea>
                  </div>
                  <button type="submit" className="btn btn-lg button-primary btn-contacto-enviar" id="btn-contacto-enviar">Enviar</button>
                </form>
               }
              </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendContacto: data => {
      dispatch(sendContacto(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactoForm);
