import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ModCabeceraImg from "./mod_cabecera_img";
import ModContacto from "./mod_contacto";
import ModMapa from "./mod_mapa";
import 'animate.css';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();


const Servicios = props => {
  const scrollToRef = ref => {
    if (ref.current) window.scrollTo(0, ref.current.offsetTop-65); 
  }

  const agronegociosRef = useRef(null);
  const capitalesRef = useRef(null);

  useEffect(() => {
    if(!props.match.params.anchor){
      window.scrollTo(0,0)
    }else if (props.match.params.anchor === "agronegocios") {
      scrollToRef(agronegociosRef);
    } else if (props.match.params.anchor === "mercado_capitales") {
      scrollToRef(capitalesRef);
    }
  });

  return (
  <div>
    <ModCabeceraImg img={window.location.origin+"/imgs/agronegocios_campo.png"} titulo="AGRONEGOCIOS" />

    <div className="agronegocios d-flex justify-content-center mt-5">

      <div className="card d-flex bg_white box-shadow" data-aos="fade-right" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
        <div className="card-context">
          <h2 className="h4 txt-primary bold">Corretaje de Granos</h2>
          <p className="txt-primary">
            La compañía nació con el negocio de la intermediación y asesoramiento del mercado de granos. A lo largo de toda nuestra trayectoria, hemos fortalecido vínculos comerciales con los principales actores del comercio agrícola. Como versa nuestra misión, contribuimos con la transparencia y desarrollo de un entorno ético para brindar seguridad a la hora de realizar un negocio disponible como futuro. La confianza queda demostrada diariamente por nuestros clientes productores, acopios, cooperativas, industriales aceiteros, exportadores molinería y consumos y se fortalece año a año.
          </p>
        </div>

        <div
          className="card-img p-5 bg_image"
          style={{backgroundImage: "url('"+window.location.origin+"/imgs/trigo.jpg')"}}
        ></div>
      </div>
      
      <div className="card-context bg_white box-shadow" data-aos="fade-left" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
        <h2 className="h4 txt-primary bold">Mercado de Futuros y Opciones Agrícolas</h2>
        <p className="txt-primary">
        Nos especializamos en los mercados de derivados agrícolas, para brindarle asesoramiento a nuestros clientes y ofrecerles estrategias de coberturas para asegurar rentabilidad en su producción. Como agente Matba-Rofex, podemos brindarles un seguro de precio para los principales cultivos del país.
        </p>
      </div>

    </div>

    <div className="agronegocios d-flex justify-content-center my-5">

      <div className="card-context bg_white box-shadow" data-aos="fade-right" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
        <h2 className="h4 txt-primary bold">Administración de canjes</h2>
        <p className="txt-primary">
        Ofrecemos a nuestros clientes, la plataforma de administración de canjes para optimizar su negocio y poder facilitar el circuito de entrega y cobro del producto.  Nuestro equipo comercial y departamento de sistemas, trabajan con las compañías proveedoras de insumos, para desarrollar un sistema a medida y facilitarle mayor automatización de procesos. Contamos con una red de laboratorios, semilleros, empresas de fertilizantes y maquinarias que confían en nuestro servicio.
        </p>
      </div>

      <div className="card d-flex bg_white box-shadow" data-aos="fade-left" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
        <div className="card-context">
          <h2 className="h4 txt-primary bold">Mercado de Insumos</h2>
          <p className="txt-primary">
          La gran demanda de agroquímicos, fertilizantes, silobolsas y
          subproductos para consumo nos exigió crear un departamento de
          ventas que con el tiempo, creció gracias a una fuerte red de
          contactos directos y alianzas estratégicas.
          </p>
        </div>

        <div
          className="card-img p-5 bg_image"
          style={{backgroundImage: "url('"+window.location.origin+"/imgs/gran-campo-arroz-verde-plantas-arroz-verde-filas.png')"}}
        ></div>
      </div>

    </div>

    <div className="modal" id="idoneos"  role="dialog">

        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Listado de Idoneos</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <img className="img-fluid"
            src={window.location.origin+"/imgs/n_resoluciones.jpg"}
          />
            <table className="table table-striped">
  <tbody>
  <tr><td>LUCIO GARCÍA MANSILLA</td></tr>
              
              <tr><td>DELFIN MORGAN</td></tr>
              <tr><td>PATRICIO GILLIGAN</td></tr>
              <tr><td>GUSTAVO SARAVIA</td></tr>
              <tr><td>SEBASTIÁN CRISTI</td></tr>
              <tr><td>EDMUNDO DONNELLY</td></tr>
  </tbody>
</table>      
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
  </div>
)
}

export default Servicios;
