import axios from 'axios'
import qs from 'qs'

export const GET_DIRECTORIO = 'GET_DIRECTORIO'
export const GET_NOVEDADES = "GET_NOVEDADES";
export const GET_MENCIONES = "GET_MENCIONES";
export const GET_PIZARRAS = "GET_PIZARRAS";
export const GET_TERMOMETRO = "GET_TERMOMETRO";
export const GET_TERMOMETRO_BOLSAR = "GET_TERMOMETRO_BOLSAR";
export const GET_TWEETS = "GET_TWEETS";
export const GET_VIDEOS = "GET_VIDEOS";
export const SEND_ERR_MSJ = "SEND_ERR_MSJ";
export const SEND_CONTACTO = "SEND_CONTACTO";
export const SEND_CV = "SEND_CV";
export const SEND_LOGIN = "SEND_LOGIN";
export const RESET_PASS = "RESET_PASS";
export const CIERRA_MODAL = "CIERRA_MODAL";
export const ABRE_MODAL = "ABRE_MODAL";
export const GET_LINEA = "GET_LINEA";

let puerto=(window.location.origin.includes("allariaagro.com.ar"))?process.env.REACT_APP_PUERTO_HTTPS:process.env.REACT_APP_PUERTO_HTTP
const url_termometro = `/datos/termometro`;
const url_termometrobolsar =`/datos/termometroFinancieroNew`;
const url_novedades = `/datos/ultimas_notas`;
const url_menciones = `/datos/menciones`;
const url_pizarras = `/datos/pizarras`;
const url_tweets = `/datos/tweets`;
const url_videos =`https://www.googleapis.com/youtube/v3/search?channelId=UC9bm09LyOZ9mjA8tZ9fII5Q&order=date&part=snippet&type=video&maxResults=4&key=AIzaSyDAbjogNksmxW-ps7l4mN3f4XVhxsAVjWg`
const url_contacto = `/datos/contacto`;
const url_cv = `/datos/cv`;
const url_login = `/datos/login`;
const url_reset_pass = `/datos/reset_pass`;
const url_linea = `/datos/linea_tiempo`;



    var axiosConfigA = {
        withCredentials: true,
        crossdomain: true,
        headers: { 
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
        "Postman-Token": "42e6c291-9a09-c29f-f28f-11872e2490a5"
        }
    };

    var axiosConfig = {
      //withCredentials: true,
      crossdomain: true,
      headers: {
        Authorization: "BEARER mjb2975",
        //"Access-Control-Allow-Origin": "*"
      }
    };

export const cierraModal = ()=>{
  return {
    type: CIERRA_MODAL
  };
}

export const sendLogin = async data => {
  
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  var config = {
    withCredentials: false,
    crossdomain: true,
    //enablePreflight: true,
    headers: {
      //'Content-Type': 'multipart/form-data'
      "Access-Control-Allow-Headers": "*",
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*"
    }
  };
  //, config
  //qs.stringify(data)

  try {
    const request = await axios.post(dest+url_login,qs.stringify(data));
    return {
      type: SEND_LOGIN,
      payload: qs.parse(request.data),
      f:data.f
    };
  } catch (error) {
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const resetPass = async data => {
  
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  
  
  try {
    const request = await axios.post(dest+url_reset_pass,qs.stringify(data));
    return {
      type: RESET_PASS,
      payload: qs.parse(request.data),
      f:data.f
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getLinea = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
    
    const request = await axios.get(dest+url_linea, axiosConfig);
    return {
      type: GET_LINEA,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getTermometro = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
    
    const request = await axios.get(dest+url_termometro, axiosConfig);
    return {
      type: GET_TERMOMETRO,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getTermometroBolsar = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
    
    const request = await axios.get(dest+url_termometrobolsar, axiosConfig);
    return {
      type: GET_TERMOMETRO_BOLSAR,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getPizarras = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
   
    const request = await axios.get(dest+url_pizarras, axiosConfig);
    return {
      type: GET_PIZARRAS,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getNovedades = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
   
    const request = await axios.get(dest+url_novedades, axiosConfig);
    return {
      type: GET_NOVEDADES,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getMenciones = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
   
    const request = await axios.get(dest+url_menciones, axiosConfig);
    return {
      type: GET_MENCIONES,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getTweets = async () => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try {
    const request = await axios.get(dest+url_tweets, axiosConfig);
    return {
      type: GET_TWEETS,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};

export const getVideos = async () => {
  try {
    const request = await axios.get(url_videos);
    return {
      type: GET_VIDEOS,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};


export const sendContacto = async data => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try{
    const request = await axios.post(dest+url_contacto, data);
      return {
        type: SEND_CONTACTO,
        payload: request
      };
    } catch (error) {
      console.log(error);
      return {
        type: SEND_ERR_MSJ,
        data: error
      };
    }
  
};

export const sendCv = async data => {
  var dest = (window.location.origin.includes("allariaagro.com.ar"))?'https://apiinfogral.allariaagro.com.ar':process.env.REACT_APP_URL_API
  try{
    const request = axios.post(dest+url_cv, data);
    return {
      type: SEND_CV,
      payload: request
    };
  } catch (error) {
    console.log(error);
    return {
      type: SEND_ERR_MSJ,
      data: error
    };
  }
};



