import React, { Component } from "react";
import { connect } from "react-redux";
import { sendLogin, resetPass } from "../actions";

class ModLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enviando: false,
      user: "",
      pass: "",
      recordarme: "",
      tipo: "agro",
      error: "",
      resp: null,
      blanque: false,
      resetpass: false,
      newpass: "",
      renewpass: "",
      message: ""
    };
  }

  componentDidMount() {
    //window.TrustLogo("https://www.mgmsa.com.ar:7813/imgs/logos/sectigo_trust_seal_sm_2x.png", "SC5", "none");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.contador_login != prevProps.data.contador_login) {
      if (this.props.data.respuesta_login.resp == 1 && this.props.data.vencimiento.vto === 1) {
        this.setState({
          resetpass: true,
          error: 'Su password ha caducado. Por favor escoga una nueva.'
        })
      } else if (this.props.data.respuesta_login.resp == 1 && this.props.data.vencimiento.vto === 0) {
        // document.getElementById("log_form_h2").submit();
      } else {
        this.setState({
          error: this.props.data.respuesta_login.msj
        })
      }
    }
  }

  enviaFormLogin = (e) => {
    e.preventDefault();
    if (this.state.user == "") {
      this.setState({
        error: "Debe completar su Usuario"
      });
    } else if (this.state.pass == "") {
      this.setState({
        error: "Debe completar su Password",
        message: ""
      });
    } else {
      const sendData = {
        user: this.state.user,
        pass: this.state.pass,
        f: 1
      };
      
      this.setState({ message: ""})
      this.props.sendLogin(sendData);
    }
  }

  enviaFormResetPass = (e) => {
    e.preventDefault();
    if (this.state.user === "") {
      this.setState({
        error: "Debe completar su Usuario"
      });
    } else if (this.state.pass === "") {
      this.setState({
        error: "Debe completar su Contraseña actual"
      });
    }  else if (this.state.pass === "") {
      this.setState({
        error: "Debe completar su nueva Contraseña"
      });
    }  else if (this.state.pass === "") {
      this.setState({
        error: "Debe completar su nuevamente la nueva Contraseña"
      });
    } else {
      if(this.state.newpass!== this.state.renewpass) {
        this.setState({
        error: "No coinciden las Contraseñas"
        })
      } else {
        const sendData = {
          user: this.state.user,
          pass: this.state.newpass,
          f: 1
        };
        this.props.resetPass(sendData);
        this.setState({ resetpass: false, message: `Cambio de contraseña exitoso!`, error: "" })
      }
    }
  }

  cambia = (key, value) => {
    this.setState({ [key]: value });
  };

  cambiaTipo = (value) => {
    this.setState({ tipo: value.target.value });
  };

  goFinanciero = (e) => {
    var dest_financiero = (window.location.origin.includes("allariaagro.com.ar")) ? 'https://financiero.allariaagro.com.ar/' : 'http://groot/'
    window.location = dest_financiero;
  }

  goLogistica = (e) => {
    var dest_logistica = (window.location.origin.includes("allariaagro.com.ar")) ? 'https://logistica.allariaagro.com.ar/' : process.env.REACT_APP_URL_LOGISTICA
    window.location = dest_logistica;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  logoOver =(event)=>{
    window.tLeB(event,'https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=C&v_shortname=SC5&v_search=https://www.allariaagro.com.ar:7510/&x=6&y=5','tl_popupSC5')
  }

  logoMove =(event)=>{
    window.tLXB(event) 
  }

  logoOut =(event)=>{
    window.tLTC('tl_popupSC5')  
  }

  render() {

    var dest = (window.location.origin.includes("allariaagro.com.ar")) ? 'https://clientes.allariaagro.com.ar' : 'http://aeon'

    return (
  
      <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <form className="form-inline" id="log_form_h2" name="log_form_h2" action={dest + "/externa/login.asp"} method="POST" style={{ display: 'none' }}>
          <input type="hidden" id="user" name="user" value={this.state.user} className="form-control form-control-sm user mr-1" />
          <input type="hidden" id="pass" name="pass" value={this.state.pass} className="form-control form-control-sm password mr-1" />
          <input type="hidden" id="origen" name="origen" value="new" className="form-control form-control-sm password mr-1" />
        </form>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title txt-primary" id="exampleModalCenterTitle">{!this.state.resetpass ? "Iniciar sesión" : "Renovar contraseña"}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="form-reset-pass" className="w-100">
                <form className="form-inline d-flex flex-column justify-content-center align-items-center" id="log_form" onSubmit={this.state.resetpass ? this.enviaFormResetPass : this.enviaFormLogin} >    
                  {this.state.error !== "" &&
                    <div class="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  }
                  {this.state.message !== "" &&
                    <div class="alert alert-success" role="alert">
                      {this.state.message}
                    </div>
                  }
                  <div className="form-group w-100 p-3">
                    <input style={{ borderRadius: "30px" }} required="required" name="user" type="text" className="form-control w-100" id="user_m" value={this.state.user} aria-describedby="" placeholder="Usuario" onChange={v => this.cambia('user', v.target.value)}/>
                  </div>
                  {
                    !this.state.resetpass && 
                    <div className="form-group w-100 p-3">
                      <input style={{ borderRadius: "30px" }} required="required" name="pass" type="password" className="form-control w-100" id="pass_m" value={this.state.pass} onChange={v => this.cambia('pass', v.target.value)} aria-describedby="" placeholder="Contraseña"/>
                    </div>
                  }

                  {
                    this.state.resetpass && (
                      <>
                        <div className="form-group w-100 p-3">
                          <input style={{ borderRadius: "30px" }} required="required" name="pass" type="password" className="form-control w-100" id="pass_m" value={this.state.pass} onChange={v => this.cambia('pass', v.target.value)} aria-describedby="" placeholder="Contraseña actual"/>
                        </div>
                        <div className="form-group w-100 p-3">
                          <input style={{ borderRadius: "30px" }} required="required" name="newpass" type="password" className="form-control w-100" id="pass_m_new" value={this.state.newpass} onChange={v => this.cambia('newpass', v.target.value)} aria-describedby="" placeholder="Nueva Contraseña"/>
                        </div>
                        <div className="form-group w-100 p-3">
                          <input style={{ borderRadius: "30px" }} required="required" name="renewpass" type="password" className="form-control w-100" id="pass_m_renew" value={this.state.renewpass} onChange={v => this.cambia('renewpass', v.target.value)} aria-describedby="" placeholder="Reingrese la nueva contraseña"/>
                        </div>
                        <button type="submit" className="btn btn-lg button-primary btn-contacto-enviar" id="btn-contacto-enviar">Enviar</button>
                      </>
                    )
                  }
                  {
                    !this.state.resetpass &&
                    <button type="submit" className="btn btn-lg button-primary btn-contacto-enviar" id="btn-contacto-enviar">Ingresar</button>
                  }
                </form>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    )
 }
}

const mapStateToProps = (state, ownProps) => {
  return {
          data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
      sendLogin: data => {
      dispatch(sendLogin(data));
    },
    resetPass: data => {
      dispatch(resetPass(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModLogin);