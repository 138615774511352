import React,{useState, useEffect,useLayoutEffect} from "react";
import ModContactoData from './mod_contacto_data'
import ModMapa from "./mod_mapa";
import FormContacto from '../containers/form_contacto'

const ModContacto = props => {

  const [cargo, set_cargo] = useState(false)

  useEffect(() => {
    window.scrollTo(0,0)
  })

  useLayoutEffect(() => {
    set_cargo(true)
  }, [])
return (
    <div className="site-section p-0">
      <div className="">
        <div className="d-flex flex-column justify-content-center align-items-center p-5" style={{visibility: "visible"}}>
          <div className="col-sm-5 p-3 text-center txt-primary">
              <p className="h2 blue" style={{marginBottom:"0.2em"}}>Contacto</p>
              <p className="h5">¡Queremos ayudarte!</p>
              <p>Si queres conocer más sobre nuestra empresa, o tenés<br/> alguna duda, eviannos un mensaje.</p>
          </div>
        </div>
          <div id="contacto" className="d-flex justify-content-center align-items-center flex-wrap mb-5">
            <FormContacto/>
            { cargo &&
              <ModMapa />
            }
          </div>    
        </div>    
      <div className="d-flex flex-wrap justify-content-center align-items-center contact_info mb-5">
        <ModContactoData texto="Reconquista 661 - 3° PISO <br> CP C1003ABM. C.A.B.A. Argentina" img="imgs/icons/location.svg"/>
        <ModContactoData texto="( 011 ) 5352.2800" img="imgs/icons/tel.svg"/>
        <ModContactoData texto="<a href='mailto:info@allariaagrocom.ar'>info@allariaagro.com.ar</a>" img="imgs/icons/envelope.svg"/>
      </div>
    </div>
    )
};

export default ModContacto;
