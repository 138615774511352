import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

const ModMarquee = (props) => {
  let store = "#"
    if( navigator.userAgent.match(/Android/i)){
      store="https://play.google.com/store/apps/details?id=ar.com.dlab.mgmapp"
    }else if(navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)){
      store="https://apps.apple.com/ar/app/mgm/id1180839614"
    }
  return(
  <div style={{ position: "relative" }}>

    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      speed={2000}
      loop={true}
      navigation={true}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="slide item slide01">
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center font-mobile">
            <h3 className="text-white header-1"><span style={{ fontWeight: "400" }}>Integramos el Mercado de Granos</span><br/>con el Mercado de Capitales<br/><span style={{ fontWeight: "400" }}>#Agrofinanzas</span></h3>
            <a href="/servicios/mercado_capitales" className="btn button-primary">Conocé más</a>
            {/* <div className="d-flex flex-md-row flex-column justify-content-center align-items-center"> */}
            {/* <div className="d-flex flex-row justify-content-center align-items-center p-3 mb-5">
              <img className="m-2 w-50 mb-sm-2 w-sm-50" src={window.location.origin + "/imgs/expoagro-logo-aaf.png"} alt="" style={{  }}/>
              <img className="mb-0 w-50 mb-sm-2 w-sm-50" src={window.location.origin + "/imgs/expoagro-logo.png"} alt="" style={{  }}/>
            </div> */}
            </div>
            {/* <h2 className="text-white h1 mt-1"><span style={{ fontWeight: "700" }}>DEL 5 AL 8 DE MARZO 2024</span></h2>
            <a href="https://www.linkedin.com/posts/allaria-agrofinanzas_cosechamosvalor-allariaagro-agrofinanzas-activity-7166848351844397056-6ch0?utm_source=share&utm_medium=member_desktop" style={{ color: "#1a316d", backgroundColor: "white", borderRadius: '30px', padding: '10px 20px', fontWeight: '700' }} className="m-5">MÁS INFORMACIÓN</a>
            <h3 className="text-white h3 mt-3"><span style={{ fontWeight: "400" }}>Te esperamos en nuestro stand P03 en la carpa cubierta 2</span></h3>
          </div> */}
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slide item slide02 d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center font-mobile">
            <h3 className="text-white header-1"><span style={{ fontWeight: "400" }}>Desde 1985 evolucionando para ofrecer</span><br/>el mejor servicio a nuestros clientes<br/><span style={{ fontWeight: "400" }}>#Trayectoria</span></h3>
            <a href="/empresa/historia" className="btn button-primary">Conocé nuestra historia</a>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slide item slide03 d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center font-mobile">
            <h3 className="text-white header-1">Respondiendo siempre con cercanía y profesionalismo<br/><span style={{ fontWeight: "400" }}>#CosechamosValor</span></h3>
            <a href="/servicios/agronegocios" className="btn button-primary">Conocé más</a>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    
    {/* <OwlCarousel
      className="owl-theme owl-carousel home wow fadeIn"
      loop
      items={1}
      margin={0}
      center={true}
      autoplay={true}
      autoplayTimeout={8000}
      autoplaySpeed={2000}
      smartSpeed={7000}
      nav={false}
    >
      <div className="slide item slide01">
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center">
          <h3 className="text-white h1"><span style={{ fontWeight: "400" }}>Integramos el Mercado de Granos</span><br/>con el Mercado de Capitales<br/><span style={{ fontWeight: "400" }}>#Agrofinanzas</span></h3>
          <a href="/servicios/mercado_capitales" className="btn button-primary">Conocé más</a>
        </div>
      </div>

      <div className="slide item slide02 d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center">
          <h3 className="text-white h1"><span style={{ fontWeight: "400" }}>Desde 1985 evolucionando para ofrecer</span><br/>el merjor servicio a nuestros clientes<br/><span style={{ fontWeight: "400" }}>#Agrofinanzas</span></h3>
          <a href="/empresa/historia" className="btn button-primary">Conocé nuestra historia</a>
        </div>
      </div>

      <div className="slide item slide03 d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center">
          <h3 className="text-white h1">Respondiendo siempre con cercanía y profesionalismo<br/><span style={{ fontWeight: "400" }}>#CosechamosValor</span></h3>
          <a href="/servicios/agronegocios" className="btn button-primary">Conocé más</a>
        </div>
      </div>
    </OwlCarousel> */}
  </div>


)};

export default ModMarquee;
