import React, { useRef, useEffect,useLayoutEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ModCabeceraImg from "./mod_cabecera_img";
import ModContacto from "./mod_contacto";
import ModMapa from "./mod_mapa";
import ModEquipo from "./mod_equipo";
import ModVisionMision from "./mod_visionmision";
import ModCv from "./mod_cv";
import LineaTiempo from "../containers/linea_tiempo";
import { LineaTiempo2 } from "./linea_tiempo2";
var directorio = require("../data/directorio.json");
var comerciales = require("../data/comercial.json");
var representantes = require("../data/representantes.json");
var sucursales = require("../data/sucursales.json");

const scrollToRef = ref => {
  if (ref.current) window.scrollTo(0, ref.current.offsetTop-65);
};

const Equipo = props => {
  const historiaRef = useRef(null);
  const equipoRef = useRef(null);
  const misionRef = useRef(null);
  const cvRef = useRef(null);
  const contactoRef = useRef(null);
  const mapaRef = useRef(null);

    useEffect(() => {
      if(!props.match.params.anchor){
        window.scrollTo(0,0)
      }else if(props.match.params.anchor==="equipo"){
        scrollToRef(equipoRef);
      }else if (props.match.params.anchor === "vision") {
        scrollToRef(misionRef);
      }else if (props.match.params.anchor === "historia") {
        scrollToRef(historiaRef);
      }
    });

    
  
   return (
    <div>
      <ModCabeceraImg img={window.location.origin+"/imgs/dos-hombres-negocios-confia-estrechar-mano-reunion-oficina-exito-trato-saludo-concepto-socio.png"} titulo="NUESTRO EQUIPO" />

      <h2 className="h2 txt-primary text-center p-5">Nuestra misión y visión</h2>

      <div className="container">
        <div className="box-shadow bg_white">
          <div className="row">
            <div className="col-sm-6">
              <div className="p-3 p-md-5">
                <h2 className="h4 blue2 bold">Misión</h2>
                <p className="mb-5 pr-3">
                Somos un equipo con solidos valores, que brinda servicios profesionales e integrales, defendiendo la transparencia del mercado, aportando conocimiento y tecnología a nuestros clientes.
                <br/><br/>
                Desde AAF hemos definido nuestra Misión, es decir nuestra esencia, como proveedores de servicios profesionales de manera integral. Aspiramos a ser referentes con fuerte foco en la transparencia de las operaciones. Entendemos que para hacerlo debemos potenciar el desarrollo de colaboradores, que integren equipos comprometidos de alto desempeño.
                </p>
              </div>
            </div>
            <div className="col-sm-6" style={{ borderLeft: "1px solid #B3B1AE"}}>
              <div className="p-3 p-md-5">
                <h2 className="h4 blue2 bold">Visión</h2>
                <p className="mb-5 pr-3">
                Ser una empresa líder, reconocida por su trayectoria, resultados y valores, contribuyendo al crecimiento de nuestros clientes.
                <br/><br/>
                Desde AAF hemos definido nuestra Visión como proveedores líderes de soluciones que generen valor a nuestros clientes.  Entendemos que debemos hacerlo con tecnología, con conocimiento y con vínculos duraderos en un marco de sustentabilidad en todo sentido.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={misionRef} >
       <ModVisionMision />
      </div>
      
      <div ref={equipoRef}>
        <ModEquipo
         directorio={directorio}
         comerciales={comerciales}
         representantes={representantes}
         sucursales={sucursales}
       />
      </div>
      
      <ModCv />
      {/* <ModContacto />
      <ModMapa /> */}

    </div>
   );
};

export default Equipo;
