import React, { Component } from "react";
import { connect } from "react-redux";
import { getTermometro,getTermometroBolsar} from "../actions";


class ModTermometro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pizarra: 1,
      momento: 0,
      datos: []
    };
  }

  componentDidMount() {
    if (this.props.tipo === "fisico") {
      this.props.getTermometro();
    } else if (this.props.tipo === "financiero") {
      this.props.getTermometroBolsar();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.tipo === "fisico" &&
      prevProps.data.termometro != this.props.data.termometro
    ) {
      this.setState({
        loading: false,
        datos: this.props.data.termometro
      });
    } else if (
      this.props.tipo === "financiero" &&
      prevProps.data.termometro_bolsar != this.props.data.termometro_bolsar
    ) {
      this.setState({
        loading: false,
        datos: this.props.data.termometro_bolsar
      });
    }
    /*
        if(!this.state.loading){
            this.setState({
                loading:false
            })
        }
        */
  }

  selectRubroBot(id) {
  }

  selectDataBot(id) {}

  getBackBotsTermometro = valor => {
    var colorBack = "";
    if (parseFloat(valor) >= 1.5) {
      colorBack = "#489E17";
    } else if (parseFloat(valor) >= 0.5 && parseFloat(valor) < 1.5) {
      colorBack = "#73CD23";
    } else if (parseFloat(valor) > 0 && parseFloat(valor) < 0.5) {
      colorBack = "#B4DC69";
    } else if (parseFloat(valor) == 0) {
      colorBack = "#D7D7D7";
    } else if (parseFloat(valor) < 0 && parseFloat(valor) >= -0.5) {
      colorBack = "#FF8787";
    } else if (parseFloat(valor) < 0.5 && parseFloat(valor) >= -1.5) {
      colorBack = "#FF5A5A";
    } else if (parseFloat(valor) < -1.5) {
      colorBack = "#E00702";
    } else {
      colorBack = "#E00702";
    }
    return colorBack;
  };

  formatoDinero = (x, moneda) => {
    //formatoDinero
    var monto;
    if (moneda === 1) {
      monto = parseFloat(x)
        .toLocaleString("de-DE", { style: "currency", currency: "ARG" })
        .replace("ARG", "$");
    } else if (moneda === 2) {
      monto = parseFloat(x)
        .toLocaleString("de-DE", { style: "currency", currency: "ARG" })
        .replace("ARG", "U$");
    } else {
      monto = parseFloat(x)
        .toLocaleString("de-DE", { style: "currency", currency: "ARG" })
        .replace("ARG", "");
    }
    return monto;
  };

  ColoreaValor = valor => {
    var color = "#ffffff";
    if (parseFloat(valor) > 0) {
      color = "#65c43a";
    } else if (parseFloat(valor) < 0) {
      color = "#ff6600";
    }
    return color;
  };

  render() {
    if (this.state.loading) return <div>CARGANDO</div>;
    var titulo =
      this.props.tipo === "fisico" ? "AGRONEGOCIOS" : "MERCADO FINANCIERO";
    var lista = this.state.datos.map((p, i) => {
      var label = this.props.tipo === "fisico" ? p.label_rubro : p.nombre;
      var valor_1 = this.props.tipo === "fisico" ? p.var : p.ultimo;
      var valor_2 = this.props.tipo === "fisico" ? p.var_por : p.tendencia;
      var id = this.props.tipo === "fisico" ? p.id_rubro : p.id_bolsar_panel;
      var col = this.props.tipo === "fisico" ? "col-4" : "col-4";
      /*<a
      href="#"
      onClick={this.selectRubroBot(i)}
      className={" p-1 " + col}
      key={"t_" + this.props.tipo + "_" + i}
    >*/
      return (
        <div
          className={" p-1 " + col}
          key={"t_" + this.props.tipo + "_" + i}
        >
          <div
            className="text-center m-1 w-100 p-2"
            style={{
              backgroundColor: this.getBackBotsTermometro(valor_2),
              width: "100%"
            }}
          >
            <h5 className="mb-1" style={{ fontSize: "14px", color: "#FFF" }}>
              {label}
            </h5>
            <p className="mb-1" style={{ fontSize: "12px", color: "#FFF" }}>
              {valor_1.toFixed(2)}
            </p>
            <p className="mb-1" style={{ fontSize: "12px", color: "#FFF" }}>
              {this.formatoDinero(parseFloat(valor_2).toFixed(2), 3)}%
            </p>
          </div>
        </div>
      );
    });

    return (
      <div className="mt-2">
        <h4>{titulo}</h4>
        <div className="row">{lista}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTermometro: () => {
      dispatch(getTermometro());
    },
    getTermometroBolsar: () => {
      dispatch(getTermometroBolsar());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModTermometro);
