import React from "react";
import { Link, Redirect } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const ModApps = props => (
  <div className="container" style={{ backgroundColor: "#f8f8f8"}} data-aos="fade-right" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
    <div className="row animate__animated animate__slideInLeft bg_white box-shadow" style={{margin: "20px 0 60px"}}>
      
      <div className="col-sm-6 p-md-5">
        <div className="p-2 p-md-4">
          <h2 className="h3 blue1 bold">Suscribite</h2>
        {/* <p className="h4 blue1 mb-5">
            a nuestro canal de Youtube Allaria Agrofinanzas TV o bajate nuestra APP Mgm para toda
            la información de mercado.
          </p> */}
          <p className="h4 blue1 mb-2">
            a nuestro canal de Youtube Allaria Agrofinanzas TV para ver toda
            la información de mercado.
          </p>
          {/* <a href="https://play.google.com/store/apps/details?id=ar.com.dlab.mgmapp" target="_blank">
                  <img
            src="imgs/logos/google-play-badge.png"
            alt="Disponible en Google Play"
            style={{ height: "42px" }}
          /> 
          </a>
          <a href="https://apps.apple.com/us/app/mgm/id1180839614?l=es&ls=1" target="_blank">
            <img
              src="imgs/logos/disponible-en-el-app-store.png"
              alt="Disponible en App Store"
              style={{ height: "42px" }}
            />
          </a>  */}
          <a href="https://www.youtube.com/@AllariaAgrofinanzastv" target="_blank">
            <img
              src="imgs/logos/youtube.png"
              alt="Canal de Youtube"
              style={{ height: "42px" }}
            />
        
          </a> 
        </div>
      </div>
        
      <div
        className="col-sm-6 p-5 bg_image"
        style={{ backgroundImage: "url('"+window.location.origin+"/imgs/suscribirse.jpg')" }}
      >
        {" "}
      </div>
    </div>
  </div>
);

export default ModApps;
