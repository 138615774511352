import React from "react";
import { Link, Redirect } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const ModContactoData = props => (
    <div className="m-3 mt-5" data-wow-duration="0.4s" style={{visibility:"visible"}} data-aos="zoom-in" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
        <div className="p-4 bg-white text-center info_pane" style={{borderRadius:"10px", width: "350px", boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25"}}>
            <img src={window.location.origin+"/"+props.img} style={{width:"60px"}}/>
            <span className="d-block txt-primary" dangerouslySetInnerHTML={ {__html:props.texto }} />
        </div>
    </div>
);

export default ModContactoData;
